/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { sepolia } from 'wagmi/chains';
import '../App.css';
import doc from '../assets/file.png';
import download from '../assets/downloads.png';
import { base } from 'wagmi/chains';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import axios from 'axios';
import html2pdf from "html2pdf.js";

import { jsPDF } from "jspdf";
import PDF from '../components/pdf';
import RegisterLogin from '../components/regsiterAndLogin';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}


let ABIToken = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "initialSupply",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "allowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSpender",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xCEcd0df29D858fEf05AE131bb22EE21b3BF6bcE9"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancelPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "returnFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setExchangeRateFixed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_holded",
				"type": "uint256"
			}
		],
		"name": "setHolded",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0x4aA1851F516769dfDEe86cdE5D85C1e77C09dfB2"

let ABIWETH = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"payable": true,
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressWETH = "0x4200000000000000000000000000000000000006"

const MyInvestments = () => {

	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_percentage, setPercentage] = useState('0%')
	const contentRefs = useRef([]); // Array to store refs for each response

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_amount, set_amount] = useState('');
	const httpURL = "https://go.getblock.io/139e814351f54f169e78cf8776eb0b1e"
	const [_connected, setConnected] = useState(false);
	const [_downloadPDF, setDownloadPDF] = useState(0);
	const [_responseData, setResponseData] = useState([]);
	const [_softCap, setSoftCap] = useState(0);
	const [_HardCapInPOL, setHardCapInPOL] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_rate, setRate] = useState(0);
	const [_ghutAmount, setGhutAmount] = useState(0);
	const { disconnect } = useDisconnect();
	const [popupIndex, setPopupIndex] = useState(null); // Track which popup is open

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: sepolia,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	var contract3 = {
		address: addressWETH,
		abi: ABIWETH
	}

	async function handleConnect() {
		if (chain.id !== 11155111) {
			switchNetwork(11155111);
		}

		//await fetchNFTs();
		setConnected(true);
	}


	useEffect(() => {

		const getAllUserTxns = async () => {

			try {

				try {

					const response = await axios.get('http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Investment/getAllInvestmentByLoggedInUser', {
						headers: {
							'Authorization': `Bearer ${sessionStorage.getItem("userToken")}`
						}
					});

					console.log('File sent successfully.');
					console.log(response.data);

					if (response.data.isSuccess) {

						console.log(response.data.data);
						setResponseData(response.data.data);

					}


				} catch (error) {
					console.log('Error sending file.', error);
				} finally {
				}

			} catch (err) {
				console.log(err);
			}
		};

		async function fetchHardCapInPOL() {
			try {
				var data = await getHardCapInPol();
				let hardCapPOL = Number(data.data);
				console.log("Hard Cap in POL: " + hardCapPOL);

				// Set the hard cap in POL (if you have a state or variable to store it)
				setHardCapInPOL(hardCapPOL);

			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}

		async function fetchData5() {

			var data = await getSoftCap();
			var softCap = Number(data.data);
			setSoftCap(softCap);
			console.log("softCap : " + softCap);
		}

		async function fetchMinAmount() {
			var data = await getMinAmount();
			let minAmount = Number(data.data);
			setMinAmount(minAmount);
			console.log("minAmount : " + minAmount);
		}


		async function fetchMaxAmount() {
			var data = await getMaxAmount();
			let maxAmount = Number(data.data);
			setMaxAmount(maxAmount);
			console.log("maxAmount : " + maxAmount);
		}

		async function ghutToPol() {
			var ghutToPol = Number(1 * (10 ** 18 / Number(_rate)))
			setGhutToPol(ghutToPol);
			console.log("ghutToPol : " + ghutToPol);
		}

		async function fetchData2() {
			var data = await getRate();
			let rate = Number(data.data);
			setRate(Number(data.data));
			console.log("GHUT AMOUNT : " + (Number(_amount) * Number(data.data)) / 10 ** 18);
			setGhutAmount((Number(_amount) * Number(data.data)) / 10 ** 18);
			console.log("rate : " + rate);
		}

		getAllUserTxns();
		fetchData5();
		fetchHardCapInPOL();
		fetchMinAmount();
		fetchMaxAmount();
		ghutToPol();
		fetchData2();

	}, [_connected, walletAddress, _GhutToPol]);

	const { refetch: getRate } = useContractRead({
		...contract,
		functionName: 'POLToGHUT', args: [],
	})

	const { refetch: getHardCapInPol } = useContractRead({
		...contract,
		functionName: 'getHardCapInPol', args: [],
	})

	const { refetch: getSoftCap } = useContractRead({
		...contract,
		functionName: 'getSoftCapInPol', args: [],
	})

	const { refetch: getMinAmount } = useContractRead({
		...contract,
		functionName: 'minAmount', args: [],
	})


	const { refetch: getMaxAmount } = useContractRead({
		...contract,
		functionName: 'maxAmount', args: [],
	})

	const downloadPDF = (index) => {
		const element = contentRefs.current[index];  // Get the specific content for the selected index
		html2pdf()
			.from(element)
			.save(`GuardHawk_SAFT_${index}.pdf`);  // Save as a unique PDF for each index
	};

	const openPopup = (index) => {
		setPopupIndex(index);  // Open the corresponding popup
	};

	const closePopup = () => {
		setPopupIndex(null);  // Close popup
	};

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
		window.location.href = '/'
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	async function downloadPDFWindow() {
		setDownloadPDF(1);
	}

	async function closeWindow() {
		setDownloadPDF(0);
	}

	const getRelativeTime = (date) => {
		const now = new Date();
		const past = new Date(date);
		const diffInSeconds = Math.floor((now - past) / 1000);

		const minutes = Math.floor(diffInSeconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
		if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
		return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
	};

	return (
		<div>
			<div className='allWrap'>
				<div class="light">
					{_connected ? (
						<div className="connectT" onClick={() => disconnectWallet()}>
							{walletAddress === "" ? "Connect Web3 Wallet" : shortenAddress(walletAddress)}
						</div>
					) : (
						<div className="connectT" onClick={() => { open(); }}>Connect Web3 Wallet</div>
					)}

					<div className='onlyMob'>
						<HeaderMobile />
					</div>

					<div className='page1Main'>
						<Header />

						<div className='rightSection'>
							<div className='mainT'>MIS INVERSIONES</div>
							<div className='flexBox3T'>HOLA, EDUARDO!</div>
							<div className='flexBox4T'>BIENVENIDO A TU ESPACIO DE INVERSIÓN</div>

							<div class="transaction-list-main">
								<div className='listT'>ÚLTIMAS TRANSACCIONES</div>
								<div className='line3'></div>

								{_responseData.slice().reverse().map((responseData, index) => (
									<div key={index}>

										<div class="transaction-list">

											<div className='list1'>
												<img src={doc} />
												<div className='listConMain'>
													<div className='list1Con'>Detalles_del_contrato_de_transacción {_responseData.length - index}</div>
													<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>

												</div>
											</div>

											{/*<div className='listConMain'>
												<div className='list1Con3'>Desde:</div>
												<div className='list1Con3'>Hacia:</div>
											</div>*/}

											<div className='listConMain'><button className='listBtn'>$$$$</button></div>

											<div className="listConMain2">
												<div className="listConMain2-2">Descargar</div>
												<div className="listConMain3">
													<div>SAFT</div>
													<img src={download} onClick={() => openPopup(index)} />
												</div>
											</div>

										</div>

										{popupIndex === index && (
											<div id="popupContainerPDF" class="popupPDF">
												<div class="close" onClick={closePopup}>✖</div>
												<div class="popup-contentPDF" >
													<div ref={el => contentRefs.current[index] = el}>
														<div className='mainTDIV'>Simple Agreement For Future Token - Guard Hawk (SAFT)</div>
														<div className='mainTDIV'>Angel Investor Round</div>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>INTRODUCTION</h2>
														<p className='txtPDF'>This Agreement provides detailed information regarding the Angel Investor round for the Guard Hawk utility token (GHUT), specifically setting forth the terms and conditions applicable to any individual or entity contemplating an investment in Guard Hawk&rsquo;s first funding round.</p>
														<p className='txtPDF'>Please be advised that a period of six months from the launch date has been designated to secure sufficient capital for the development of the Project.</p>

														<p>&nbsp;</p>
														<h2 className='txtPDF'>DEFINITIONS</h2>
														<p className='txtPDF'>&ldquo;<strong>SAFT</strong>&rdquo; (Simple Agreement for Future Tokens): An instrument granting future rights to an Investor to receive a specified quantity of Tokens in consideration of their investment.</p>
														<p className='txtPDF'>&ldquo;<strong>Company</strong>&rdquo;: Refers to the legal entity &ldquo;Guard Hawk.&rdquo;</p>
														<p className='txtPDF'>&ldquo;<strong>Investor</strong>&rdquo;: Refers to any legal entity or individual investing, which provides funds in exchange for future Tokens.</p>
														<p className='txtPDF'>&ldquo;<strong>Utility Token (GHUT)&rdquo;:</strong> A type of digital asset that provides a user with access to a specific product or service.</p>
														<p className='txtPDF'>&ldquo;<strong>Governance Token (GHGO)&rdquo;:</strong> A type of digital asset that grants holders the right to participate in the decision-making processes of a decentralized organization or protocol.</p>
														<p className='txtPDF'>&ldquo;<strong>NFT (Non-Fungible Token)&rdquo;:</strong> A unique digital asset representing objects or concepts in the digital world, such as art, music, games, and other collectibles. In the context of Guard Hawk, a collectible NFT is a digital artwork representing the Guard Hawk logo, granting its owner a unique piece of digital memorabilia linked to the brand.</p>
														<p className='txtPDF'>&ldquo;<strong>Dissolution Event</strong>&rdquo;: Refers to (a) a voluntary termination of operations by the Company at its sole discretion; (b) a general assignment for the benefit of the Company&rsquo;s creditors; or (c) any other event of liquidation, dissolution, or winding up of the Company, excluding changes in ownership and control of the Company or an initial public offering.</p>
														<p className='txtPDF'>&ldquo;<strong>Subsequent Agreement</strong>&rdquo;: Refers to any SAFT issued by the Company subsequent to this SAFT for future rounds of Token sales in accordance with the principles and for the purpose of raising capital. This definition excludes: (a) token issuances under the Company&rsquo;s internal distribution plan, such as team incentives and other relevant events; (b) tokens issued to external service providers or other persons connected and associated with the Network launch or similar provision of goods and services to the Company; (c) Tokens issued in connection with research, collaboration, licensing, development, partnerships, and other applicable sponsored agreements; (d) any convertible securities issued by the Company; and (e) Tokens issued in connection with Network mining activities and giveaways initiated by the Company.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>BUSINESS STRUCTURE</h2>
														<ul>
															<li>A total of 20,000,000,000 GHUT shall be issued.</li>
															<li>100,000,000 GHUT shall be allocated for distribution among Angel Investors.</li>
															<li>The total amount to be raised in this round shall range between {Number(_softCap / 10 ** 18).toFixed(2)} POL and {Number(_HardCapInPOL / 10 ** 18).toFixed(2)} POL</li>
															<li>The value of GHUT for Angel Investors is set at {_GhutToPol.toFixed(8)} POL.</li>
															<li>The minimum investment in this round is {_MinAmount / 10 ** 18} POL, and the maximum is {_MaxAmount / 10 ** 18} POL</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>INVESTMENT</h2>
														<ul>
															<li>Wallet No. {(responseData.ethAddress)} shall purchase {responseData.ghutAmount} GHUT.</li>
															<li>The price per GHUT is {_GhutToPol.toFixed(8)} POL.</li>
															<li>The total purchase value is {responseData.ethAmount} POL.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>DURATION</h2>
														<p className='txtPDF'>The Angel Investor round shall commence on September 15, 2024, and conclude on March 1, 2025, UTC time.</p>
														<p className='txtPDF'>Fifteen percent (15%) of the total GHUT tokens are allocated to Investors. This allocation shall be distributed across three funding rounds: 0.5% to Angel Investors, 6% in the First Funding Round, 3.5% in the Second Round, and 5% to Launchpad investors.</p>
														<p className='txtPDF'>The initial value of GHUT is set at {_GhutToPol.toFixed(8)} POL, reflecting our operating costs and projected demand, providing a clear and justified starting point. It is important to acknowledge that the market itself shall determine the actual value of GHUT over time.</p>
														<p className='txtPDF'>These terms certify that, in exchange for payment by the Investor, the Company grants the Investor the right to the quantity of GHUT tokens purchased, subject to the terms set forth herein.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>BENEFITS</h2>
														<p className='txtPDF'>Investors in the first round of financing for the Guard Hawk project shall receive several exclusive benefits, including:</p>
														<ul>
															<li>GHUT (Guard Hawk Utility Token) according to the amount invested.</li>
															<li>1 GHGO Token (Guard Hawk Governance Token).</li>
															<li>1 Exclusive NFT (Non-Fungible Token): Investors will acquire a unique Guard Hawk NFT that provides access to exclusive products and services.</li>
														</ul>
														<p>&nbsp;</p>
														<p>&nbsp;</p>

														<h2 className='txtPDF'>TERMS OF SALE</h2>
														<h4 className='txtPDF'>Reservation of Rights:</h4>
														<p className='txtPDF'>The Company reserves the right to offer and sell the Token on different terms and in multiple rounds. The terms applicable to each subsequent round may be modified, provided that the total supply of tokens is not exceeded.</p>
														<h4 className='txtPDF'>Delivery of Investment:</h4>
														<p className='txtPDF'>By entering into this Agreement with the Company in accordance with the provisions herein, the Investor agrees to transfer the Purchase Amount corresponding to the Token value and in accordance with these terms. By accepting this document, the Investor agrees to be contractually bound by its terms.</p>
														<h4 className='txtPDF'>Conditions for Delivery of GHUT, GHGO Tokens, and NFTs:</h4>
														<p className='txtPDF'>The corresponding amount of GHUT, GHGO tokens, and NFTs shall be delivered to the Investor&rsquo;s digital wallet as provided by the Investor in this document. The Token shall be delivered to the Wallet under the direct or indirect control of the Investor and cannot be delegated to any third party, whether authorized or unauthorized, including the Investor&rsquo;s representatives. The Tokens and NFTs shall be delivered to the Wallet address specified by the Investor within a maximum of 7 calendar days from the end of the six-month period set for capital raising, provided that the capital raising target is met. Otherwise, the amount transferred by the Investor shall be returned to their wallet in accordance with Smart Contract code {responseData.ethContract}, link https://sepolia.etherscan.io/address/{responseData.ethContract}.</p>
														<h4 className='txtPDF'>Termination:</h4>
														<p className='txtPDF'>This SAFT and its provisions shall be considered terminated if:</p>
														<ul>
															<li>The applicable amount of Token is delivered to the Investor within 7 calendar days from the end of the six-month capital raising period, provided that the required capital has been successfully raised; or</li>
															<li>The investment is returned to the Investor as provided by the smart contract if, after the six-month period for capital raising has expired, the required amount has not been raised.</li>
														</ul>
														<p className='txtPDF'>Network transaction costs shall be borne solely by the Investor.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>LOCK-UP CONDITIONS</h2>
														<p className='txtPDF'>The lock-up (vesting) and release structure of GHUT for the first funding round is designed to align Investor interests with Guard Hawk&rsquo;s long-term growth and stability. The following structure reflects a fair and balanced approach:</p>
														<h4 className='txtPDF'>Lock-Up Period (Cliff):</h4>
														<p className='txtPDF'>Angel Investor Lock-Up: 100% of the GHUT tokens issued in this funding round, representing 0.5% of the total tokens issued, shall be locked for 1 year from the token launch date. After this period, 10% of the tokens shall be released in the first month, and the remaining 90% shall be released proportionally over the next 18 months.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>PROTECTIVE CLAUSES</h2>
														<ul>
															<li>Reactivation of Lock-Up Clause: If a significant incident, anomaly, or force majeure directly or indirectly affects the future viability of the project or the interests of investors, the committee may, in the event of an unfavorable risk report, reactivate the token lock-up for a maximum of 7 consecutive days. If the committee determines that the stated period is insufficient to rectify the incident or force majeure, Governance must decide on maintaining the lock-up for a longer period, based on the applicable risk reports. This mechanism ensures that any action taken aligns with the interests of the community and token holders, providing an additional layer of security and transparency. Governance will play a crucial role in assessing the situation and making informed decisions, always considering the best interests of the Project.</li>
															<li>Cryptographic Wallet Freezing Clause (Blacklist): At the request of regulators and/or competent courts, wallets engaging in fraudulent activities or failing to meet regulatory requirements and minimum security standards may be frozen. This measure is critical for maintaining Investor confidence as it prevents them from investing in the same project as unreliable investors. These lists are designed to prevent fraud and scams.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>TRANSPARENCY AND COMMUNICATION</h2>
														<ul>
															<li>Clear Announcements: All lock-up and release terms shall be clearly announced before investors commit their capital.</li>
															<li>Continuous Communication: We shall maintain open communication channels, such as Discord, Telegram, and others indicated on our website. This allows investors to express opinions and submit proposals to Governance, facilitating adjustments to policies as necessary for the benefit of the Project and the community.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>WARRANTY</h2>
														<p className='txtPDF'>The Guard Hawk financing proposal is distinguished by the security it offers to Investors. Guard Hawk guarantees automatic reimbursement of investments if financial objectives are not met within the established timeframe through the application of Smart Contract code {responseData.ethContract}, available at link https://sepolia.etherscan.io/address/{responseData.ethContract}. In the event of a refund, it shall be made in POL, with transaction costs borne by the Investor.</p>
														<p className='txtPDF'>This provision substantially mitigates risk for Investors, thereby enhancing confidence in the Project&rsquo;s responsible management and viability.</p>
														<p className='txtPDF'>The Project shall be audited by an international firm during the development stage to ensure that development milestones are met and expenses remain transparent, with results published on our website. This additional transparency is essential for maintaining Investor confidence.</p>
														<p className='txtPDF'>It is important to note that we shall close the initial investor raising if we reach our funding target within 6 months, with a maximum limit set between {Number(_MinAmount / 10 ** 18)} POL and {Number(_MaxAmount / 10 ** 18)} POL.</p>
														<p className='txtPDF'>Additionally, future tokenomics projections stipulate a continuous distribution for market liquidity through various exchanges&mdash;both centralized and decentralized&mdash;to allow free entry and exit movements into the ecosystem.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>COMPANY STATEMENTS</h2>
														<p className='txtPDF'>T The Company is a duly incorporated, validly existing, and in good standing under the laws of the state of its incorporation.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>INVESTOR REPRESENTATIONS</h2>
														<p className='txtPDF'>As an Investor, you represent and warrant that:</p>
														<ul>
															<li>You have read and accepted this Agreement, which constitutes an integral and binding part between the parties, prior to investing in the Guard Hawk Project.</li>
															<li>You have read and accepted the terms and conditions of the Guard Hawk platform, including the legal notice and privacy policy.</li>
															<li>The funds you provide originate from lawful activities in compliance with European Union regulations.</li>
															<li>You will not allow third parties to deposit funds into your accounts that originate from unlawful activities, in compliance with European Union regulations; nor will you engage in transactions intended for such activities or on behalf of persons associated with them.</li>
															<li>You agree to terminate any agreement, business, or contract entered into with Guard Hawk if you breach any provision of this document, thereby releasing Guard Hawk from any liability arising from any erroneous, false, or inaccurate information you have provided or from a breach of the terms and conditions set forth in this document.</li>
															<li>You authorize Guard Hawk to verify whether you have been sanctioned by any European or U.S. authority in order to confirm the above statements.</li>
															<li>You authorize Guard Hawk to engage third parties for identity verification services that will be used when registering as an Investor.</li>
															<li>You have reached the age of majority in your country of residence and therefore have the legal capacity to enter into this SAFT and be bound by its terms.</li>
															<li>Entering into this binding Agreement with the Company and the obligation to comply with the terms and provisions of this SAFT do not contradict or breach any other contractual terms by which you are legally bound at the time this SAFT takes effect.</li>
															<li>You are not located in or a resident of any restricted locations and jurisdictions, including those subject to prohibitive regulations, international financial sanctions, and economic restriction measures.</li>
														</ul>
														<p className='txtPDF'>If the Investor is a legal entity, the Investor further represents and warrants that:</p>
														<ul>
															<li>The organization is duly constituted with all necessary diligence regarding its formation and incorporation in its country of domicile and conducts its activities in accordance with applicable laws and regulations.</li>
															<li>The representative of the organization acting as a signatory of this SAFT has been duly authorized to represent the organization and enter into legally binding agreements on its behalf.</li>
															<li>The organization does not engage in business activities related to illegal commercial practices, intellectual property rights infringement, regulated and licensed goods and services, fraudulent and restricted financial services and schemes, or other unfair, deceptive, aggressive, and unregulated goods and services in violation of relevant laws.</li>
														</ul>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>CONFIDENTIALITY</h2>
														<p className='txtPDF'>All confidential information disclosed or to be disclosed to the Investor during the capital raising period, and all rights to such information, shall remain the property of Guard Hawk.</p>
														<p className='txtPDF'>Investors shall not acquire any rights of any kind to the information nor any rights to use the information except for the purposes of this Agreement. The disclosure of confidential information does not imply the granting of licenses for patent rights, copyrights, or any other rights by Guard Hawk, except as expressly set forth in this document.</p>
														<p className='txtPDF'>Investors agree to maintain the confidentiality of all information (i.e., any information related to commercial, financial, and marketing strategies) in strict confidence and not to disclose any such information to any other party, related or otherwise, without the prior written consent of Guard Hawk.</p>
														<p className='txtPDF'>If the Investor is a legal entity, its representative must inform any person responsible for receiving confidential information&mdash;and must obtain their signature on the confidentiality agreement if necessary&mdash;of their obligation to receive, process, and use any confidential information solely for the purposes of this Agreement, under the terms set forth herein.</p>
														<p className='txtPDF'>Investors shall treat all information received directly or indirectly from Guard Hawk as confidential and shall not use such information in any manner other than for the purposes of this Agreement. Investors shall not handle, use, exploit, or disclose confidential information to any person or entity for any reason in contravention of this instrument unless expressly authorized by Guard Hawk in writing.</p>
														<p className='txtPDF'>Disclosure or use of information by an Investor in breach of this Agreement shall be grounds for damages compensation.</p>
														<p className='txtPDF'>The obligations provided in this document shall not apply in the following cases:</p>
														<ol>
															<li>Information that is in the public domain or hereafter becomes part of the public domain.</li>
															<li>Information that has not been obtained from Guard Hawk or must be disclosed by law or by order of an authority or court.</li>
															<li>If the information or any part thereof is legally obtained by the Investor from a third party without breaching this Agreement, provided that the third party is a legitimate source of information.</li>
															<li>If the information or any part thereof was known by the Investor prior to its disclosure by Guard Hawk, provided that the Investor can demonstrate such knowledge.</li>
														</ol>
														<p className='txtPDF'>Investors acknowledge that the above exceptions shall not include combinations of types of information simply because each of them is in the public domain or in the possession of the Investor. Investors shall bear the burden of demonstrating their rights concerning any exception provided in this clause.</p>
														<p className='txtPDF'>This Agreement does not constitute a guarantee to the Investor by Guard Hawk regarding any infringement related to patent information or other third-party rights. Guard Hawk shall not be liable for the duration of information disclosure, for any errors or omissions in the information, or for the use and results of the use of the information. Guard Hawk shall not be liable in any manner for any loss of any kind, including but not limited to damages and losses, interest costs, lost profits, or any other similar loss or damage resulting from any error, inaccuracy, omission, or any other defect in the information.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>LIABILITY</h2>
														<p className='txtPDF'>Under no circumstances does Guard Hawk guarantee the success of the Project. However, the team shall make every effort to develop and execute the Project as described in the whitepaper. Please note that there is always the possibility of force majeure or other contingencies that may require changes to or termination of the Project. Investment carries inherent risks, including the possibility that Guard Hawk may not adhere to the roadmap established in its whitepaper. Before investing in Guard Hawk, Investors should assess the associated risks and, if necessary, seek advice from an external expert. Investors who do not accept the financial or compliance risks associated with this Project should refrain from investing in Guard Hawk.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>RECEIPT OF NOTIFICATIONS</h2>
														<p className='txtPDF'>Receipt of any notification related to this Agreement shall only be valid if in writing and sent from the corresponding email address of the respective Party as indicated in this Agreement to the email address of the other Party, under the same conditions.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>LIMITATION OF REPRESENTATION</h2>
														<p className='txtPDF'>The Investor shall have no right of representation in the Company nor any right to vote or manage the Company. Likewise, the Investor shall have no right to any position on the Company&rsquo;s board of directors nor to the acquisition or transfer of any title or interest in the Company&rsquo;s assets based solely on their holding of the Token. Furthermore, the Investor shall not have the right to vote for the election or removal of any member of the Company&rsquo;s board of directors, nor to receive notice of meetings, nor to receive any associated rights thereof.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>SEVERABILITY</h2>
														<p className='txtPDF'>If any provision or provisions of this instrument are found to be invalid, illegal, or unenforceable, in whole or in part, or in any aspect, or if one or more provisions of this instrument operate or may prospectively operate to invalidate this instrument, then, in such cases, only such provision(s) shall be considered null and void and shall not affect any other provision of this instrument. The remaining provisions of this instrument shall remain operative and in full force and effect and shall not be affected, impaired, or disturbed thereby.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>NO FINANCIAL ADVICE</h2>
														<p className='txtPDF'>The Investor warrants that this Agreement and any information provided by Guard Hawk shall not be construed as investment, financial, regulatory, tax, or legal advice and that the Investor has sought such advice at their discretion and independently from professionals with relevant qualifications and/or licenses. Any decision regarding the Investor&rsquo;s conclusion concerning the purchase of GHUT, whether made with or without the assistance of the relevant advisor, shall be their sole responsibility.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>INVESTOR KNOWLEDGE</h2>
														<p className='txtPDF'>The Investor acknowledges and warrants that they possess sufficient knowledge in financial and business matters to evaluate the risks and benefits associated with the purchase, sale, and trading of virtual currencies, including tokens. The Investor further acknowledges the inherent risks of investing in virtual currencies and has assessed their financial situation, considering whether the purchase of the Token is appropriate for them. The Investor accepts that they are aware of the possibilities of total and irreversible loss of their financial assets and that recovery from such loss may be difficult or impossible.</p>
														<p>&nbsp;</p>
														<h2 className='txtPDF'>THIRD-PARTY RELATIONSHIPS</h2>
														<p className='txtPDF'>The Investor reaffirms their commitment to purchasing the Token for their own account, solely for investment purposes, and not as a nominee or agent, nor with a view to or for the resale thereof in connection with its distribution. The Investor has no present intention of selling, granting any participation in, or distributing the Token.</p>
														<p>&nbsp;</p>
														<p className='txtPDF'>SIGNATURES</p>
														<p>&nbsp;</p>
														<p>&nbsp;</p>
														<p className='txtPDF'>_______________________</p>
														<p className='txtPDF'>GERMAN MU&Ntilde;OZ MENESES</p>
														<p className='txtPDF'>CEO</p>
														<p className='txtPDF'>GUARD HAWK LDA</p>
														<p>&nbsp;</p>
														<p>&nbsp;</p>
														<p className='txtPDF'>____________________</p>
														<p className='txtPDF'>Wallet No. {(responseData.ethAddress)}</p>

													</div>
													<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

												</div>
											</div>
										)}
									</div>
								))}

								{_responseData.length === 0 && <p className='notxns'>No transactions yet</p>}

								<div className='listNote'>VER TODAS LAS TRANSACCIONES</div>
							</div>
							<div className='onlyMob'>
								<div class="transaction-list-main-mob">
									<div className='listT'>ÚLTIMAS TRANSACCIONES</div>
									<div className='line3'></div>
									{_responseData.slice().reverse().map((responseData, index) => (
										<div key={index}>
											<div class="transaction-list">
												<div className='flexDoc1'>
													<div className='list1'>
														<img src={doc} />
														<div className='listConMain'>
															<div className='list1Con'>nombre del documento {_responseData.length - index}</div>
															<div className='list1Con2'>{getRelativeTime(responseData.createdDate)}</div>
														</div>
													</div>

													{/*<div className='listConMain'>
														<div className='list1Con3'>Desde:</div>
														<div className='list1Con3'>Hacia:</div>
													</div>*/}
												</div>

												<div className='flexDoc2'>
													<button className='listBtn'>$$$$</button>

													<div className="listConMain2">
														<div className="listConMain2-2">Descargar</div>
														<div className="listConMain3">
															<div>SAFT</div>
															<img src={download} onClick={() => openPopup(index)} />
														</div>
													</div>
												</div>
											</div>

											{popupIndex === index && (
												<div id="popupContainerPDF" class="popupPDF">
													<div class="close" onClick={closePopup}>✖</div>
													<div class="popup-contentPDF" >
														<div ref={el => contentRefs.current[index] = el}>
															<div className='mainTDIV'>Simple Agreement For Future Token - Guard Hawk (SAFT)</div>
															<div className='mainTDIV'>Angel Investor Round</div>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>INTRODUCTION</h2>
															<p className='txtPDF'>This Agreement provides detailed information regarding the Angel Investor round for the Guard Hawk utility token (GHUT), specifically setting forth the terms and conditions applicable to any individual or entity contemplating an investment in Guard Hawk&rsquo;s first funding round.</p>
															<p className='txtPDF'>Please be advised that a period of six months from the launch date has been designated to secure sufficient capital for the development of the Project.</p>

															<p>&nbsp;</p>
															<h2 className='txtPDF'>DEFINITIONS</h2>
															<p className='txtPDF'>&ldquo;<strong>SAFT</strong>&rdquo; (Simple Agreement for Future Tokens): An instrument granting future rights to an Investor to receive a specified quantity of Tokens in consideration of their investment.</p>
															<p className='txtPDF'>&ldquo;<strong>Company</strong>&rdquo;: Refers to the legal entity &ldquo;Guard Hawk.&rdquo;</p>
															<p className='txtPDF'>&ldquo;<strong>Investor</strong>&rdquo;: Refers to any legal entity or individual investing, which provides funds in exchange for future Tokens.</p>
															<p className='txtPDF'>&ldquo;<strong>Utility Token (GHUT)&rdquo;:</strong> A type of digital asset that provides a user with access to a specific product or service.</p>
															<p className='txtPDF'>&ldquo;<strong>Governance Token (GHGO)&rdquo;:</strong> A type of digital asset that grants holders the right to participate in the decision-making processes of a decentralized organization or protocol.</p>
															<p className='txtPDF'>&ldquo;<strong>NFT (Non-Fungible Token)&rdquo;:</strong> A unique digital asset representing objects or concepts in the digital world, such as art, music, games, and other collectibles. In the context of Guard Hawk, a collectible NFT is a digital artwork representing the Guard Hawk logo, granting its owner a unique piece of digital memorabilia linked to the brand.</p>
															<p className='txtPDF'>&ldquo;<strong>Dissolution Event</strong>&rdquo;: Refers to (a) a voluntary termination of operations by the Company at its sole discretion; (b) a general assignment for the benefit of the Company&rsquo;s creditors; or (c) any other event of liquidation, dissolution, or winding up of the Company, excluding changes in ownership and control of the Company or an initial public offering.</p>
															<p className='txtPDF'>&ldquo;<strong>Subsequent Agreement</strong>&rdquo;: Refers to any SAFT issued by the Company subsequent to this SAFT for future rounds of Token sales in accordance with the principles and for the purpose of raising capital. This definition excludes: (a) token issuances under the Company&rsquo;s internal distribution plan, such as team incentives and other relevant events; (b) tokens issued to external service providers or other persons connected and associated with the Network launch or similar provision of goods and services to the Company; (c) Tokens issued in connection with research, collaboration, licensing, development, partnerships, and other applicable sponsored agreements; (d) any convertible securities issued by the Company; and (e) Tokens issued in connection with Network mining activities and giveaways initiated by the Company.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>BUSINESS STRUCTURE</h2>
															<ul>
																<li>A total of 20,000,000,000 GHUT shall be issued.</li>
																<li>100,000,000 GHUT shall be allocated for distribution among Angel Investors.</li>
																<li>The total amount to be raised in this round shall range between {Number(_softCap / 10 ** 18).toFixed(2)} POL and {Number(_HardCapInPOL / 10 ** 18).toFixed(2)} POL</li>
																<li>The value of GHUT for Angel Investors is set at {_GhutToPol.toFixed(8)} POL.</li>
																<li>The minimum investment in this round is {_MinAmount / 10 ** 18} POL, and the maximum is {_MaxAmount / 10 ** 18} POL</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>INVESTMENT</h2>
															<ul>
																<li>Wallet No. {(responseData.ethAddress)} shall purchase {responseData.ghutAmount} GHUT.</li>
																<li>The price per GHUT is {_GhutToPol.toFixed(8)} POL.</li>
																<li>The total purchase value is {responseData.ethAmount} POL.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>DURATION</h2>
															<p className='txtPDF'>The Angel Investor round shall commence on September 15, 2024, and conclude on March 1, 2025, UTC time.</p>
															<p className='txtPDF'>Fifteen percent (15%) of the total GHUT tokens are allocated to Investors. This allocation shall be distributed across three funding rounds: 0.5% to Angel Investors, 6% in the First Funding Round, 3.5% in the Second Round, and 5% to Launchpad investors.</p>
															<p className='txtPDF'>The initial value of GHUT is set at {_GhutToPol.toFixed(8)} POL, reflecting our operating costs and projected demand, providing a clear and justified starting point. It is important to acknowledge that the market itself shall determine the actual value of GHUT over time.</p>
															<p className='txtPDF'>These terms certify that, in exchange for payment by the Investor, the Company grants the Investor the right to the quantity of GHUT tokens purchased, subject to the terms set forth herein.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>BENEFITS</h2>
															<p className='txtPDF'>Investors in the first round of financing for the Guard Hawk project shall receive several exclusive benefits, including:</p>
															<ul>
																<li>GHUT (Guard Hawk Utility Token) according to the amount invested.</li>
																<li>1 GHGO Token (Guard Hawk Governance Token).</li>
																<li>1 Exclusive NFT (Non-Fungible Token): Investors will acquire a unique Guard Hawk NFT that provides access to exclusive products and services.</li>
															</ul>
															<p>&nbsp;</p>
															<p>&nbsp;</p>

															<h2 className='txtPDF'>TERMS OF SALE</h2>
															<h4 className='txtPDF'>Reservation of Rights:</h4>
															<p className='txtPDF'>The Company reserves the right to offer and sell the Token on different terms and in multiple rounds. The terms applicable to each subsequent round may be modified, provided that the total supply of tokens is not exceeded.</p>
															<h4 className='txtPDF'>Delivery of Investment:</h4>
															<p className='txtPDF'>By entering into this Agreement with the Company in accordance with the provisions herein, the Investor agrees to transfer the Purchase Amount corresponding to the Token value and in accordance with these terms. By accepting this document, the Investor agrees to be contractually bound by its terms.</p>
															<h4 className='txtPDF'>Conditions for Delivery of GHUT, GHGO Tokens, and NFTs:</h4>
															<p className='txtPDF'>The corresponding amount of GHUT, GHGO tokens, and NFTs shall be delivered to the Investor&rsquo;s digital wallet as provided by the Investor in this document. The Token shall be delivered to the Wallet under the direct or indirect control of the Investor and cannot be delegated to any third party, whether authorized or unauthorized, including the Investor&rsquo;s representatives. The Tokens and NFTs shall be delivered to the Wallet address specified by the Investor within a maximum of 7 calendar days from the end of the six-month period set for capital raising, provided that the capital raising target is met. Otherwise, the amount transferred by the Investor shall be returned to their wallet in accordance with Smart Contract code {responseData.ethContract}, link https://sepolia.etherscan.io/address/{responseData.ethContract}.</p>
															<h4 className='txtPDF'>Termination:</h4>
															<p className='txtPDF'>This SAFT and its provisions shall be considered terminated if:</p>
															<ul>
																<li>The applicable amount of Token is delivered to the Investor within 7 calendar days from the end of the six-month capital raising period, provided that the required capital has been successfully raised; or</li>
																<li>The investment is returned to the Investor as provided by the smart contract if, after the six-month period for capital raising has expired, the required amount has not been raised.</li>
															</ul>
															<p className='txtPDF'>Network transaction costs shall be borne solely by the Investor.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>LOCK-UP CONDITIONS</h2>
															<p className='txtPDF'>The lock-up (vesting) and release structure of GHUT for the first funding round is designed to align Investor interests with Guard Hawk&rsquo;s long-term growth and stability. The following structure reflects a fair and balanced approach:</p>
															<h4 className='txtPDF'>Lock-Up Period (Cliff):</h4>
															<p className='txtPDF'>Angel Investor Lock-Up: 100% of the GHUT tokens issued in this funding round, representing 0.5% of the total tokens issued, shall be locked for 1 year from the token launch date. After this period, 10% of the tokens shall be released in the first month, and the remaining 90% shall be released proportionally over the next 18 months.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>PROTECTIVE CLAUSES</h2>
															<ul>
																<li>Reactivation of Lock-Up Clause: If a significant incident, anomaly, or force majeure directly or indirectly affects the future viability of the project or the interests of investors, the committee may, in the event of an unfavorable risk report, reactivate the token lock-up for a maximum of 7 consecutive days. If the committee determines that the stated period is insufficient to rectify the incident or force majeure, Governance must decide on maintaining the lock-up for a longer period, based on the applicable risk reports. This mechanism ensures that any action taken aligns with the interests of the community and token holders, providing an additional layer of security and transparency. Governance will play a crucial role in assessing the situation and making informed decisions, always considering the best interests of the Project.</li>
																<li>Cryptographic Wallet Freezing Clause (Blacklist): At the request of regulators and/or competent courts, wallets engaging in fraudulent activities or failing to meet regulatory requirements and minimum security standards may be frozen. This measure is critical for maintaining Investor confidence as it prevents them from investing in the same project as unreliable investors. These lists are designed to prevent fraud and scams.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>TRANSPARENCY AND COMMUNICATION</h2>
															<ul>
																<li>Clear Announcements: All lock-up and release terms shall be clearly announced before investors commit their capital.</li>
																<li>Continuous Communication: We shall maintain open communication channels, such as Discord, Telegram, and others indicated on our website. This allows investors to express opinions and submit proposals to Governance, facilitating adjustments to policies as necessary for the benefit of the Project and the community.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>WARRANTY</h2>
															<p className='txtPDF'>The Guard Hawk financing proposal is distinguished by the security it offers to Investors. Guard Hawk guarantees automatic reimbursement of investments if financial objectives are not met within the established timeframe through the application of Smart Contract code {responseData.ethContract}, available at link https://sepolia.etherscan.io/address/{responseData.ethContract}. In the event of a refund, it shall be made in POL, with transaction costs borne by the Investor.</p>
															<p className='txtPDF'>This provision substantially mitigates risk for Investors, thereby enhancing confidence in the Project&rsquo;s responsible management and viability.</p>
															<p className='txtPDF'>The Project shall be audited by an international firm during the development stage to ensure that development milestones are met and expenses remain transparent, with results published on our website. This additional transparency is essential for maintaining Investor confidence.</p>
															<p className='txtPDF'>It is important to note that we shall close the initial investor raising if we reach our funding target within 6 months, with a maximum limit set between {Number(_MinAmount / 10 ** 18)} POL and {Number(_MaxAmount / 10 ** 18)} POL.</p>
															<p className='txtPDF'>Additionally, future tokenomics projections stipulate a continuous distribution for market liquidity through various exchanges&mdash;both centralized and decentralized&mdash;to allow free entry and exit movements into the ecosystem.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>COMPANY STATEMENTS</h2>
															<p className='txtPDF'>T The Company is a duly incorporated, validly existing, and in good standing under the laws of the state of its incorporation.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>INVESTOR REPRESENTATIONS</h2>
															<p className='txtPDF'>As an Investor, you represent and warrant that:</p>
															<ul>
																<li>You have read and accepted this Agreement, which constitutes an integral and binding part between the parties, prior to investing in the Guard Hawk Project.</li>
																<li>You have read and accepted the terms and conditions of the Guard Hawk platform, including the legal notice and privacy policy.</li>
																<li>The funds you provide originate from lawful activities in compliance with European Union regulations.</li>
																<li>You will not allow third parties to deposit funds into your accounts that originate from unlawful activities, in compliance with European Union regulations; nor will you engage in transactions intended for such activities or on behalf of persons associated with them.</li>
																<li>You agree to terminate any agreement, business, or contract entered into with Guard Hawk if you breach any provision of this document, thereby releasing Guard Hawk from any liability arising from any erroneous, false, or inaccurate information you have provided or from a breach of the terms and conditions set forth in this document.</li>
																<li>You authorize Guard Hawk to verify whether you have been sanctioned by any European or U.S. authority in order to confirm the above statements.</li>
																<li>You authorize Guard Hawk to engage third parties for identity verification services that will be used when registering as an Investor.</li>
																<li>You have reached the age of majority in your country of residence and therefore have the legal capacity to enter into this SAFT and be bound by its terms.</li>
																<li>Entering into this binding Agreement with the Company and the obligation to comply with the terms and provisions of this SAFT do not contradict or breach any other contractual terms by which you are legally bound at the time this SAFT takes effect.</li>
																<li>You are not located in or a resident of any restricted locations and jurisdictions, including those subject to prohibitive regulations, international financial sanctions, and economic restriction measures.</li>
															</ul>
															<p className='txtPDF'>If the Investor is a legal entity, the Investor further represents and warrants that:</p>
															<ul>
																<li>The organization is duly constituted with all necessary diligence regarding its formation and incorporation in its country of domicile and conducts its activities in accordance with applicable laws and regulations.</li>
																<li>The representative of the organization acting as a signatory of this SAFT has been duly authorized to represent the organization and enter into legally binding agreements on its behalf.</li>
																<li>The organization does not engage in business activities related to illegal commercial practices, intellectual property rights infringement, regulated and licensed goods and services, fraudulent and restricted financial services and schemes, or other unfair, deceptive, aggressive, and unregulated goods and services in violation of relevant laws.</li>
															</ul>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>CONFIDENTIALITY</h2>
															<p className='txtPDF'>All confidential information disclosed or to be disclosed to the Investor during the capital raising period, and all rights to such information, shall remain the property of Guard Hawk.</p>
															<p className='txtPDF'>Investors shall not acquire any rights of any kind to the information nor any rights to use the information except for the purposes of this Agreement. The disclosure of confidential information does not imply the granting of licenses for patent rights, copyrights, or any other rights by Guard Hawk, except as expressly set forth in this document.</p>
															<p className='txtPDF'>Investors agree to maintain the confidentiality of all information (i.e., any information related to commercial, financial, and marketing strategies) in strict confidence and not to disclose any such information to any other party, related or otherwise, without the prior written consent of Guard Hawk.</p>
															<p className='txtPDF'>If the Investor is a legal entity, its representative must inform any person responsible for receiving confidential information&mdash;and must obtain their signature on the confidentiality agreement if necessary&mdash;of their obligation to receive, process, and use any confidential information solely for the purposes of this Agreement, under the terms set forth herein.</p>
															<p className='txtPDF'>Investors shall treat all information received directly or indirectly from Guard Hawk as confidential and shall not use such information in any manner other than for the purposes of this Agreement. Investors shall not handle, use, exploit, or disclose confidential information to any person or entity for any reason in contravention of this instrument unless expressly authorized by Guard Hawk in writing.</p>
															<p className='txtPDF'>Disclosure or use of information by an Investor in breach of this Agreement shall be grounds for damages compensation.</p>
															<p className='txtPDF'>The obligations provided in this document shall not apply in the following cases:</p>
															<ol>
																<li>Information that is in the public domain or hereafter becomes part of the public domain.</li>
																<li>Information that has not been obtained from Guard Hawk or must be disclosed by law or by order of an authority or court.</li>
																<li>If the information or any part thereof is legally obtained by the Investor from a third party without breaching this Agreement, provided that the third party is a legitimate source of information.</li>
																<li>If the information or any part thereof was known by the Investor prior to its disclosure by Guard Hawk, provided that the Investor can demonstrate such knowledge.</li>
															</ol>
															<p className='txtPDF'>Investors acknowledge that the above exceptions shall not include combinations of types of information simply because each of them is in the public domain or in the possession of the Investor. Investors shall bear the burden of demonstrating their rights concerning any exception provided in this clause.</p>
															<p className='txtPDF'>This Agreement does not constitute a guarantee to the Investor by Guard Hawk regarding any infringement related to patent information or other third-party rights. Guard Hawk shall not be liable for the duration of information disclosure, for any errors or omissions in the information, or for the use and results of the use of the information. Guard Hawk shall not be liable in any manner for any loss of any kind, including but not limited to damages and losses, interest costs, lost profits, or any other similar loss or damage resulting from any error, inaccuracy, omission, or any other defect in the information.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>LIABILITY</h2>
															<p className='txtPDF'>Under no circumstances does Guard Hawk guarantee the success of the Project. However, the team shall make every effort to develop and execute the Project as described in the whitepaper. Please note that there is always the possibility of force majeure or other contingencies that may require changes to or termination of the Project. Investment carries inherent risks, including the possibility that Guard Hawk may not adhere to the roadmap established in its whitepaper. Before investing in Guard Hawk, Investors should assess the associated risks and, if necessary, seek advice from an external expert. Investors who do not accept the financial or compliance risks associated with this Project should refrain from investing in Guard Hawk.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>RECEIPT OF NOTIFICATIONS</h2>
															<p className='txtPDF'>Receipt of any notification related to this Agreement shall only be valid if in writing and sent from the corresponding email address of the respective Party as indicated in this Agreement to the email address of the other Party, under the same conditions.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>LIMITATION OF REPRESENTATION</h2>
															<p className='txtPDF'>The Investor shall have no right of representation in the Company nor any right to vote or manage the Company. Likewise, the Investor shall have no right to any position on the Company&rsquo;s board of directors nor to the acquisition or transfer of any title or interest in the Company&rsquo;s assets based solely on their holding of the Token. Furthermore, the Investor shall not have the right to vote for the election or removal of any member of the Company&rsquo;s board of directors, nor to receive notice of meetings, nor to receive any associated rights thereof.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>SEVERABILITY</h2>
															<p className='txtPDF'>If any provision or provisions of this instrument are found to be invalid, illegal, or unenforceable, in whole or in part, or in any aspect, or if one or more provisions of this instrument operate or may prospectively operate to invalidate this instrument, then, in such cases, only such provision(s) shall be considered null and void and shall not affect any other provision of this instrument. The remaining provisions of this instrument shall remain operative and in full force and effect and shall not be affected, impaired, or disturbed thereby.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>NO FINANCIAL ADVICE</h2>
															<p className='txtPDF'>The Investor warrants that this Agreement and any information provided by Guard Hawk shall not be construed as investment, financial, regulatory, tax, or legal advice and that the Investor has sought such advice at their discretion and independently from professionals with relevant qualifications and/or licenses. Any decision regarding the Investor&rsquo;s conclusion concerning the purchase of GHUT, whether made with or without the assistance of the relevant advisor, shall be their sole responsibility.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>INVESTOR KNOWLEDGE</h2>
															<p className='txtPDF'>The Investor acknowledges and warrants that they possess sufficient knowledge in financial and business matters to evaluate the risks and benefits associated with the purchase, sale, and trading of virtual currencies, including tokens. The Investor further acknowledges the inherent risks of investing in virtual currencies and has assessed their financial situation, considering whether the purchase of the Token is appropriate for them. The Investor accepts that they are aware of the possibilities of total and irreversible loss of their financial assets and that recovery from such loss may be difficult or impossible.</p>
															<p>&nbsp;</p>
															<h2 className='txtPDF'>THIRD-PARTY RELATIONSHIPS</h2>
															<p className='txtPDF'>The Investor reaffirms their commitment to purchasing the Token for their own account, solely for investment purposes, and not as a nominee or agent, nor with a view to or for the resale thereof in connection with its distribution. The Investor has no present intention of selling, granting any participation in, or distributing the Token.</p>
															<p>&nbsp;</p>
															<p className='txtPDF'>SIGNATURES</p>
															<p>&nbsp;</p>
															<p>&nbsp;</p>
															<p className='txtPDF'>_______________________</p>
															<p className='txtPDF'>GERMAN MU&Ntilde;OZ MENESES</p>
															<p className='txtPDF'>CEO</p>
															<p className='txtPDF'>GUARD HAWK LDA</p>
															<p>&nbsp;</p>
															<p>&nbsp;</p>
															<p className='txtPDF'>____________________</p>
															<p className='txtPDF'>Wallet No. {(responseData.ethAddress)}</p>

														</div>
														<button onClick={() => downloadPDF(index)} className="downloadSaft">DOWNLOAD SAFT PDF</button>

													</div>
												</div>
											)}

										</div>

									))}

									{_responseData.length === 0 && <p className='notxns'>No transactions yet</p>}

									<div className='listNote'>VER TODAS LAS TRANSACCIONES</div>
								</div>

							</div>
						</div>
					</div>

				</div>
				<img className='grid' src={grid} />

			</div>

			<RegisterLogin />

			<div className='onlyMob'>
				<HeaderNavBar />
			</div>

		</div>
	)

}
export default MyInvestments;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useDisconnect, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { sepolia } from 'wagmi/chains';
import '../App.css';
import axios from 'axios';
import gift from '../assets/gift.svg';
import audit from '../assets/auditace.png';
import Enmascarar from '../assets/Enmascarar.png';
import matic from '../assets/polygon.png';
import Header from '../components/headers';
import grid from '../assets/grid.png';
import { Web3 } from "web3";
import { parseUnits } from 'ethers';
import HeaderMobile from '../components/headersMobile';
import HeaderNavBar from '../components/headersNavBar';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
	window.open("https://x.com/basemewcoin/");
}

const tg = () => {
	window.open("https://t.me/basemewcoinchat");
}


let ABIToken = [
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "initialSupply",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "allowance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientAllowance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "balance",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "needed",
				"type": "uint256"
			}
		],
		"name": "ERC20InsufficientBalance",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC20InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "ERC20InvalidSpender",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressToken = "0xCEcd0df29D858fEf05AE131bb22EE21b3BF6bcE9"

let ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Cancel",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Claim",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "softCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "hardCap",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "startAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint32",
				"name": "endAt",
				"type": "uint32"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "minAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "maxAmount",
				"type": "uint256"
			}
		],
		"name": "Launch",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeConfirmed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "PledgeIntention",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "caller",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Refund",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "EURtoPOL",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToEUR",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "POLToGHUT",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancel",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cancelPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "cancelPledgeTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "canceled",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_wallet",
				"type": "address"
			}
		],
		"name": "confirmPledge",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalCorrecter",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "distribution",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "eligibleGHUTTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "endAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "exchangeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getChainlinkDataFeedLatestAnswer_USD_POL",
		"outputs": [
			{
				"internalType": "int256",
				"name": "",
				"type": "int256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getHardCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getPledgeAddressList",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getSoftCapInPol",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "hardCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "holded",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "holdedAmountInEuros",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			},
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			},
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "launch",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledge",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "pledgeList",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pledged",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "pledgedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "refund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "wallet",
				"type": "address"
			}
		],
		"name": "refundTo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "returnFunds",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_EUR",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_df",
				"type": "address"
			}
		],
		"name": "setDataFeed_USD_POL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalCorrecter",
				"type": "uint256"
			}
		],
		"name": "setDecimalCorrecter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_endAt",
				"type": "uint32"
			}
		],
		"name": "setEndAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_exchangeRate",
				"type": "uint256"
			}
		],
		"name": "setExchangeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setExchangeRateFixed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_hardCap",
				"type": "uint256"
			}
		],
		"name": "setHardCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_holded",
				"type": "uint256"
			}
		],
		"name": "setHolded",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maxAmount",
				"type": "uint256"
			}
		],
		"name": "setMaxAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minAmount",
				"type": "uint256"
			}
		],
		"name": "setMinAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_pledged",
				"type": "uint256"
			}
		],
		"name": "setPledged",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_softCap",
				"type": "uint256"
			}
		],
		"name": "setSoftCap",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_startAt",
				"type": "uint32"
			}
		],
		"name": "setStartAt",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "softCap",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startAt",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawAnyLeftbalance",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];
let address = "0x4aA1851F516769dfDEe86cdE5D85C1e77C09dfB2"

let ABIWETH = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"payable": true,
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "guy",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"payable": true,
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": true,
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"payable": false,
		"stateMutability": "view",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "address",
				"name": "src",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "dst",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"constant": false,
		"inputs": [
			{
				"internalType": "uint256",
				"name": "wad",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"payable": false,
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
let addressWETH = "0x4200000000000000000000000000000000000006"

const RegisterLogin = () => {

	const [_percentage, setPercentage] = useState('0%');
	const { open } = useWeb3Modal();
	const { chain } = useNetwork();
	const { switchNetwork } = useSwitchNetwork();
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [_amount, set_amount] = useState(1000);
	const httpURL = "https://go.getblock.io/139e814351f54f169e78cf8776eb0b1e"
	const [_quantity, set_quantity] = useState(0);
	const [_validate, set_validate] = useState(0);
	const [_error, set_error] = useState(0);
	const [showErrorDiv, setshowErrorDiv] = useState(false);
	const [statusError, setstatusError] = useState(false);
	const [statusLoading, setstatusLoading] = useState(false);
	const [_owner, set_owner] = useState("");
	const [_connected, setConnected] = useState(false);
	const { disconnect } = useDisconnect();
	const [_success1, setSuccessMsg1] = useState("");
	const [_loading1, setLoadingMsg1] = useState("");
	const [_ErrorMsg1, setErrorMsg1] = useState("");
	const [success, setsuccess] = useState(false);
	const [_viewUser, set_getUser] = useState("");
	const [_totalUsers, set_totalUsers] = useState("");
	const [_getAddressById, set_getAddressById] = useState("");
	const [txnHash, setTxnHash] = useState('');
	const [_totalTokensSold, setTotalTokensSold] = useState(0);
	const [_totalTokensForSale, setTotalTokensForSale] = useState(0);
	const [_targetFunds, setTargetFunds] = useState(0);
	const [_totalFundsCollected, setTotalFundsCollected] = useState(0);
	const [_rate, setRate] = useState(0);
	const [_pledged, setPledged] = useState(0);
	const [_hardCap, setHardCap] = useState(0);
	const [_EURToPOL, setEURToPOL] = useState(0);
	const [_HardCapInPOL, setHardCapInPOL] = useState(0);
	const [_softCap, setSoftCap] = useState(0);
	const [_login, set_login] = useState(0);
	const [_register, set_register] = useState(0);
	const [_softCapTargetPercentage, set_softCapTargetPercentage] = useState('0%');
	const [_pledgedPercentage, set_pledgedPercentage] = useState('0%');
	const options = countryList().getData();
	const [dob, setDob] = useState(null);
	const [day, setDay] = useState('');
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const [_BirthDate, setBirthDate] = useState(0);
	const [_Country, setCountry] = useState('');
	const [_Password, setPassword] = useState('');
	const [_Password2, setPassword2] = useState('');
	const [_Email, setEmail] = useState('');
	const [_OTP, set_OTP] = useState('');
	const [_FirstName, setFirstName] = useState('');
	const [_LastName, setLastName] = useState('');
	const [_PostalCode, setPostalCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [_ghutAmount, setGhutAmount] = useState(0);
	const [_GhutToPol, setGhutToPol] = useState(0);
	const [_EndAt, setEndAt] = useState(0);
	const [_StartAt, setStartAt] = useState(0);
	const [_MaxAmount, setMaxAmount] = useState(0);
	const [_MinAmount, setMinAmount] = useState(0);
	const [_pol1000ToGhut, setPol1000ToGhut] = useState(0);
	const [_userToken, setUserToken] = useState(0);
	const [_ShowMsgDiv, setShowMsgDiv] = useState(0);
	const [isForgetPassSuccess, setIsForgetPassSuccess] = useState(null);
	const [isLoginSuccess, setIsLoginSuccess] = useState(null);
	const [isRegisterSuccess, setIsRegisterSuccess] = useState(null);
	const [isResetPassSuccess, setIsResetPassSuccess] = useState(null);
	const [_forgotpassdiv, setforgotpassdiv] = useState(0);
	const [_resetPass, set_resetPass] = useState(0);
	const [responseMessage, setResponseMessage] = useState('');
	const [_ImgData, setImgData] = useState('');

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = http(httpURL);

	const publicClient = createPublicClient({
		chain: sepolia,
		transport
	})

	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: addressToken,
		abi: ABIToken
	}

	var contract3 = {
		address: addressWETH,
		abi: ABIWETH
	}

	// Helper function to generate options
	const generateOptions = (start, end) => {
		const options = [];
		for (let i = start; i <= end; i++) {
			options.push({ value: i, label: i });
		}
		return options;
	};

	const days = generateOptions(1, 31);
	const months = generateOptions(1, 12);
	const years = generateOptions(1924, new Date().getFullYear());

	// Combine day, month, and year into a single date variable
	const getDateValue = () => {
		if (day && month && year) {
			const date = new Date(year, month - 1, day); // Months are zero-based in JavaScript
			return date.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
		}
		return '';
	};

	const changeHandler = country => {
		setCountry(country);
	};

	const handleDateChange = (date) => {
		setDob(date);
	};

	async function handleConnect() {
		if (chain.id !== 11155111) {
			switchNetwork(11155111);
		}

		//await fetchNFTs();
		setConnected(true);
	}

	function formatNumber(number) {
		// Convert number to string and split into integer and decimal parts
		let [integerPart, decimalPart] = number.toString().split('.');

		// Add commas every three digits from the right for the integer part
		integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		// Combine the integer part with the decimal part if it exists
		return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
	}

	useEffect(() => {

		let hardcap;
		let softCap;
		let pledged;

		var _userToken2 = sessionStorage.getItem("userToken");
		setUserToken(_userToken2);
		console.log("_userToken2 : " + _userToken2);
		fetchData2();

		async function pol1000ToGhut() {
			var pol1000ToGhut = ((_rate * 1000) / 10 ** 18);
			setPol1000ToGhut(pol1000ToGhut);
			console.log("_pol1000ToGhut : " + pol1000ToGhut);
		}

		async function fetchData2() {
			var data = await getRate();
			let rate = Number(data.data);
			setRate(Number(data.data));
			console.log("GHUT AMOUNT : " + (Number(_amount) * Number(data.data)) / 10 ** 18);
			setGhutAmount((Number(_amount) * Number(data.data)) / 10 ** 18);
			console.log("rate : " + rate);
		}


		async function fetchData5() {

			var data = await getSoftCap();
			softCap = Number(data.data);
			setSoftCap(softCap);
			console.log("softCap : " + softCap);

			var data = await getHardCap();
			hardcap = Number(data.data);
			setHardCap(hardcap);
			console.log("hardcap : " + hardcap);


			var data = await getPledged();
			pledged = Number(data.data);
			setPledged(pledged);
			console.log("pledged : " + pledged);

			await caculatePledgedPercentage();
			await calculateSoftCapPercentage();
		}

		async function fetchHardCapInPOL() {
			try {
				var data = await getHardCapInPol();
				let hardCapPOL = Number(data.data);
				console.log("Hard Cap in POL: " + hardCapPOL);

				// Set the hard cap in POL (if you have a state or variable to store it)
				setHardCapInPOL(hardCapPOL);

			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		}

		// Call the function to fetch and calculate the hard cap in POL
		fetchHardCapInPOL();


		async function calculateSoftCapPercentage() {
			var softCapTargetPercentage = (softCap * 100) / hardcap;
			set_softCapTargetPercentage(softCapTargetPercentage.toFixed(2));
			console.log("softCapTargetPercentage: " + softCapTargetPercentage);
		}

		async function caculatePledgedPercentage() {
			console.log("pledged_percentage : " + pledged);
			console.log("hardcap_percentage : " + _HardCapInPOL);

			var pledgedPercentage = Number((pledged * 100) / _HardCapInPOL);
			//set_pledgedPercentage(Number(pledgedPercentage));
			set_pledgedPercentage(pledgedPercentage.toFixed(2) + '%');
			console.log("pledgedPercentage: " + pledgedPercentage);
		}

		async function tokenAmount() {
			var data = await getTokenAmount();
			let tokenAmount = Number(data.data);
			setRate(tokenAmount);
			console.log("tokenAmount : " + tokenAmount);
		}

		async function fetchMinAmount() {
			var data = await getMinAmount();
			let minAmount = Number(data.data);
			setMinAmount(minAmount);
			console.log("minAmount : " + minAmount);
		}


		async function fetchMaxAmount() {
			var data = await getMaxAmount();
			let maxAmount = Number(data.data);
			setMaxAmount(maxAmount);
			console.log("maxAmount : " + maxAmount);
		}

		async function fetchStartAt() {
			var data = await getTokenAmount();
			let startAt = Number(data.data);
			setStartAt(startAt);
			console.log("startAt : " + startAt);
		}

		async function fetchEndAt() {
			var data = await getTokenAmount();
			let endAt = Number(data.data);
			setEndAt(endAt);
			console.log("endAt : " + endAt);
		}

		ghutToPol();
		fetchData5();
		calculateSoftCapPercentage();
		caculatePledgedPercentage();
		fetchHardCapInPOL();

		fetchMinAmount();
		fetchMaxAmount();
		fetchStartAt();
		fetchEndAt();
		pol1000ToGhut();

		if (_connected) {
			tokenAmount();
			if (!_userToken2) {
				loginWindow()
			}
		}

		console.log("_connected : " + _connected);
		console.log("_login : " + _login);

	}, [_connected, walletAddress, _ghutAmount, _amount, _GhutToPol, _pol1000ToGhut]);


	/*async function imageGet() {

		//USER IMAGE SECTION

		const response = await axios.get(`http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Account/User/${sessionStorage.getItem('userId')}`);
		console.log('Login completed successfully!');

		//USER IMAGE SECTION

	}*/

	async function ghutToPol() {
		var ghutToPol = Number(1000 * (10 ** 18 / Number(_rate)))
		setGhutToPol(ghutToPol);
		console.log("ghutToPol : " + ghutToPol);
	}

	function shortenAddress(walletAddress) {
		try {
			return _connected
				? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
				: "Connect";
		} catch (error) {
			console.log(error);
		}
	}

	async function disconnectWallet() {
		setConnected(false);
		disconnect();
		window.location.reload(true);
	}

	const { writeAsync } = useContractWrite({
		onError(error) {
			if (error.message.includes("balance")) {
				setstatusError(true);
				setstatusLoading(false);
			}
		},
	});

	const { refetch: getRate } = useContractRead({
		...contract,
		functionName: 'POLToGHUT', args: [],
	})

	const { refetch: getHardCap } = useContractRead({
		...contract,
		functionName: 'hardCap', args: [],
	})


	const { refetch: getMinAmount } = useContractRead({
		...contract,
		functionName: 'minAmount', args: [],
	})


	const { refetch: getMaxAmount } = useContractRead({
		...contract,
		functionName: 'maxAmount', args: [],
	})

	const { refetch: getStartAt } = useContractRead({
		...contract,
		functionName: 'startAt', args: [],
	})

	const { refetch: getEndAt } = useContractRead({
		...contract,
		functionName: 'endAt', args: [],
	})

	const { refetch: getHardCapInPol } = useContractRead({
		...contract,
		functionName: 'getHardCapInPol', args: [],
	})

	const { refetch: getSoftCap } = useContractRead({
		...contract,
		functionName: 'softCap', args: [],
	})

	const { refetch: getPledged } = useContractRead({
		...contract,
		functionName: 'pledged', args: [],
	})

	const { refetch: getTokenAmount } = useContractRead({
		...contract2,
		functionName: 'balanceOf', args: [walletAddress],
	})

	const showLoginSuccessmsg = async () => {
		return (

			<div class="popup-containerMain3">
				<div class="popup-container3">
					<div class="popup success">
						<div className="close" onClick={closediv}>✖</div>
						<div className='review'>Thank you for joining us.</div>
					</div>
				</div>
			</div>
		)
	};

	async function validateWindow() {
		set_validate(1);
	}

	async function loginWindow() {
		set_login(1);
		set_register(0);
	}

	async function regsiterWindow() {
		set_register(1);
		set_login(0);
		setforgotpassdiv(0); // Reset the state to hide the divs
	}

	async function closeWindow() {
		set_validate(0);
		setshowErrorDiv(false);
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			borderRadius: '5px',
			borderColor: '#ccc',
			padding: '5px',
			margin: '10px 0',
			textAlign: 'left',
			boxShadow: state.isFocused ? `0 0 0 2px #8c40dd` : 'none', // Outline color on focus
			border: state.isFocused ? '1px solid #8c40dd' : '1px solid #ccc', // Border color change on focus
		}),
		placeholder: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		singleValue: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		menu: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
		option: (provided) => ({
			...provided,
			textAlign: 'left',
		}),
	};

	const registerUser = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {
			const dateValue = getDateValue();

			const payload = {
				email: _Email,
				firstName: _FirstName,
				lastName: _LastName,
				postalCode: _PostalCode,
				password: _Password,
				userRoleId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				country: _Country.value,
				dateOfBirth: dateValue,
			};

			if (_connected) {
				payload.walletAddress = walletAddress;
			}

			const response = await axios.post('http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/User', payload);
			console.log('Registration completed successfully!');
			console.log(response.data);

			// Only navigate to the login page if registration was successful
			if (response.data.isSuccess) {
				setUserToken(response.data.token); // Save the token if available
				setIsRegisterSuccess(true);
				setResponseMessage('Registration successful! Redirecting to login...');
				loginWindow(); // Show login form only if registration was successful
			} else {
				console.log('Registration was not successful:', response.data.message);
				setIsRegisterSuccess(false);
				setResponseMessage(response.data.message || 'Registration failed.');
			}
		} catch (error) {
			console.log('Error during registration:', error);
			// If there's a server error, show the error message if available
			setResponseMessage(error.response?.data?.message || 'Error occurred during registration.');
			setIsRegisterSuccess(false);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	const loginUser = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("LOGIN");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {
			const payload = {
				email: _Email,
				password: _Password,
			};

			if (_connected) {
				payload.walletAddress = walletAddress;
			}

			console.log("Login USER");

			const response = await axios.post('http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Account/login', payload);
			console.log('Login completed successfully!');
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('displayName', response.data.data.displayName);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('isValidIdentity', response.data.data.isVerified);
				console.log("IsverifiedLogin : " + response.data.data.isVerified);
				console.log("isValidIdentity_session : " + sessionStorage.getItem('isValidIdentity'));

				var fullImg = 'data:image/png;base64,' + response.data.data.imgUrl;
				sessionStorage.setItem('imgUrl', fullImg);
				setImgData(fullImg);
	
				console.log("userIDREAD" + response.data.data.userId);
				sessionStorage.setItem('userId', response.data.data.userId);

				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsLoginSuccess(true); // Set success
				setResponseMessage('Login successful!');
				set_login(0); // Close the login window on success
			} else {
				setIsLoginSuccess(false); // Set error
				setResponseMessage(response.data.message || 'Login failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during login.';
			setIsLoginSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	const forgotPassword = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("forgotPassword");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {
			const payload = {
				email: _Email,
				password: '',
			};

			const response = await axios.post('http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Account/fogetPassword', payload);
			console.log('Password resets successfully!');
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('userId', response.data.data.userId);
				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsForgetPassSuccess('Successfully OTP Sent!');
				setforgotpassdiv(0); // Close the login window on success
				set_resetPass(1);
			} else {
				setIsForgetPassSuccess(false); // Set error
				setResponseMessage(response.data.message || 'OTP sent failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during reset.';
			setIsForgetPassSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};


	const resetPassword = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior

		console.log("resetPassword");
		setResponseMessage(''); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		console.log("_Email :" + _Email);
		console.log("_OTP :" + _OTP);
		console.log("_Password :" + _Password);

		try {
			const payload = {
				email: _Email,
				otp: _OTP,
				newPassword: _Password,
			};

			const response = await axios.post('http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Account/resetPassword', payload);
			console.log(response.data);

			if (response.data.isSuccess) {
				const token = response.data.data.token; // Extract the token from the response
				setUserToken(token);
				console.log('Password resets successfully!');
				sessionStorage.setItem('userToken', token);
				sessionStorage.setItem('email', response.data.data.email);
				sessionStorage.setItem('userId', response.data.data.userId);
				console.log("userID get : " + sessionStorage.getItem("userId"));

				setIsResetPassSuccess(true); // Set success
				setResponseMessage('Password resets successful!');
				//setforgotpassdiv(0); // Close the login window on success
				set_resetPass(0);
				set_login(1);
			} else {
				setIsResetPassSuccess(false); // Set error
				setResponseMessage(response.data.message || 'Password resets failed.');
			}
		} catch (error) {
			console.log('Error during login.', error);
			// Extract and display the exact error message if available
			const errorMsg = error.response?.data?.message || 'Error occurred during reset.';
			setIsForgetPassSuccess(false); // Set error
			setResponseMessage(errorMsg);
		} finally {
			setIsLoading(false); // Set loading to false
		}
	};

	// Close the popup div
	const closediv = () => {
		setIsLoginSuccess(null); // Reset the state to hide the divs
		setIsRegisterSuccess(null);
		setIsForgetPassSuccess(null);
		setIsResetPassSuccess(null);
	};

	const forgotpassdiv = () => {
		setforgotpassdiv(1); // Reset the state to hide the divs
		set_login(0);
		set_register(0);
	};

	const closeForgotPassDiv = () => {
		setforgotpassdiv(0); // Reset the state to hide the divs
		set_login(1);
		set_resetPass(0);
	};

	return (
		<div className='allWrapMain'>

			{_register > 0 ?
				<div class="register-overlay">
					<div class="register-popup">
						<div className="close2" onClick={() => disconnectWallet()}>✖</div>

						<h2>Register</h2>
						<form onSubmit={registerUser} method="post" encType="multipart/form-data">
							<div className='regsiterFlex'>
								<div className='leftSectionWidth'>
									<input type="text" placeholder="First name" required name="_FirstName" value={_FirstName}
										onChange={event => setFirstName(event.target.value)} />
									<input type="text" placeholder="Last Name" required name="_LastName" value={_LastName}
										onChange={event => setLastName(event.target.value)} />
									<input type="email" placeholder="Enter your email" required name="_Email" value={_Email}
										onChange={event => setEmail(event.target.value)} />
									{/*<input type="password" placeholder="Confirm your password" required name="_Password" value={_Password}
										onChange={event => setPassword(event.target.value)} />*/}

									<input type="password" placeholder="Enter your password" required name="_Password" value={_Password}
										onChange={event => setPassword(event.target.value)} />

									<Select
										options={options}
										name="_Country" value={_Country}
										onChange={changeHandler}
										placeholder="Select your country"
										styles={customStyles} // Apply custom styles		
										id="selectSection"

									/>

								</div>


								<div className='rightSectionWidth'>

									<label id="birthTxt">Date of Birth</label>
									<div className="dob-section">
										<Select
											options={days}
											value={day ? { value: day, label: day } : null}
											onChange={(selected) => setDay(selected.value)}
											placeholder="Day"
											styles={customStyles}
											required
											id="selectSection"

										/>
										<Select
											options={months}
											value={month ? { value: month, label: month } : null}
											onChange={(selected) => setMonth(selected.value)}
											placeholder="Month"
											styles={customStyles}
											required
											id="selectSection"
										/>
										<Select
											options={years}
											value={year ? { value: year, label: year } : null}
											onChange={(selected) => setYear(selected.value)}
											placeholder="Year"
											styles={customStyles}
											required
											id="selectSection"

										/>

										<input type="text" placeholder="Postal code" required name="_PostalCode" value={_PostalCode}
											onChange={event => setPostalCode(event.target.value)} />

									</div>

								</div>
							</div>
							<button type="submit" class="register-btn">Register</button>
							<div class="cancel-btn" onClick={() => disconnectWallet()}>Cancel</div>
						</form>

						<div className='accountCreate'>Already have an account? <span className='rh' onClick={loginWindow}>Login here</span></div>

					</div>
				</div> : null}

			{_connected && (_login > 0) ? (

				<div class="login-overlay">
					<div class="login-popup">
						<div className="close2" onClick={() => disconnectWallet()}>✖</div>

						<h2>Login</h2>
						<form onSubmit={loginUser} method="post" encType="multipart/form-data">
							<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Enter your email" required name="_Email" value={_Email}
								onChange={event => setEmail(event.target.value)} />
							<input type="password" placeholder="Enter your password" required name="_Password" value={_Password}
								onChange={event => setPassword(event.target.value)} />
							{/*<div>
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
							<button type="submit" class="login-btn">Login</button>
							<div class="forgot-password" onClick={forgotpassdiv}>Forgot your password?</div>
						</form>
						<div className='accountCreate'>Don't have an account? <span className='rh' onClick={regsiterWindow}>Register here</span></div>
					</div>
				</div>
			) : null}

			{_forgotpassdiv > 0 ?
				<div class="login-overlay">
					<div class="login-popup">
						<div className="close2" onClick={closeForgotPassDiv}>✖</div>

						<h2>Password Reset</h2>
						<form onSubmit={forgotPassword} method="post" encType="multipart/form-data">
							<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Enter your email" required name="_Email" value={_Email}
								onChange={event => setEmail(event.target.value)} />

							{/*<div>
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
							<button type="submit" class="login-btn">Send Request</button>
						</form>
						<div className='accountCreate'>Don't have an account? <span className='rh' onClick={regsiterWindow}>Register here</span></div>
					</div>
				</div> : null}

			{/*<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review"><span className='log' onClick={afterRegister}>Login Now</span></div>
							</div>
						</div>
					</div>*/}
			<div>
				{isLoginSuccess === true && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review">Thank you for joining us.</div>
							</div>
						</div>
					</div>
				)}

				{isLoginSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Please try again.</div>
							</div>
						</div>
					</div>
				)}

				{isResetPassSuccess === true && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup success">
								<div className="close" onClick={closediv}>✖</div>
								<p className="responseSuccess">{responseMessage}</p>
								<div className="review">Your password has been successfully reset</div>
							</div>
						</div>
					</div>
				)}

				{isResetPassSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Please try again.</div>
							</div>
						</div>
					</div>
				)}

				{_resetPass > 0 ? (
					<div class="login-overlay">
						<div class="login-popup">
							<div className="close2" onClick={closeForgotPassDiv}>✖</div>

							<h2>Password Reset</h2>

							<form onSubmit={resetPassword} method="post" encType="multipart/form-data">
								<input type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Enter your email" required name="_Email" value={_Email}
									onChange={event => setEmail(event.target.value)} />

								<input type="text" placeholder="OTP" required name="_OTP" value={_OTP}
									onChange={event => set_OTP(event.target.value)} />

								<input type="password" placeholder="Enter your password" required name="_Password" value={_Password}
									onChange={event => setPassword(event.target.value)} />

								{/*<div>
							<input type="checkbox" id="remember-me" />
							<label id="remember-me2" for="remember-me">Remember me</label>
						</div>*/}
								<button type="submit" class="login-btn">Reset</button>
							</form>
						</div>
					</div>
				) : null}

				{isForgetPassSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Please try again.</div>
							</div>
						</div>
					</div>
				)}

				{isRegisterSuccess === false && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<div className="close" onClick={closediv}>✖</div>
								<p className='responseSuccess'>{responseMessage}</p>
								<div className='review2'>Please try again.</div>
							</div>
						</div>
					</div>
				)}

				{isLoading && (
					<div className="popup-containerMain3">
						<div className="popup-container3">
							<div className="popup error">
								<p class="responseSuccess">Loading...</p>
								<div className='responseSuccess'>Please wait while we process your request</div>
							</div>
						</div>
					</div>
				)}

			</div>


			{/*{_ErrorMsg1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess">Sorry Something went wrong</p>
							<div className='review2'>An error occurred while processing your request. Please try again.</div>
						</div>
					</div>
				</div>)
				: null}

			{_loading1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess">Loading..</p>
							<div className="review">Thank you for joining us.</div>
						</div>
					</div>
				</div>)
				: null}

				
			{_success1 ? (
				<div className="popup-containerMain3">
					<div className="popup-container3">
						<div className="popup success">
							<div className="close" onClick={closediv}>✖</div>
							<p className="responseSuccess"></p>
							<div className="review">Thank you for joining us.</div>
						</div>
					</div>
				</div>)
				: null}
			*/}

		</div>
	)

}
export default RegisterLogin;

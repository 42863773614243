/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState, useContext } from "react";
import { Web3Button, Web3Modal, useWeb3Modal } from "@web3modal/react";
import Cookie from "js.cookie";
import {
  mainnet,
  useDisconnect,
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  useNetwork,
  usePublicClient,
  useSwitchNetwork,
} from "wagmi";
import { createPublicClient, formatEther, http, parseEther } from "viem";
import { sepolia } from "wagmi/chains";
import "../App.css";
import axios from "axios";
import gift from "../assets/gift.svg";
import audit from "../assets/auditace.png";
import Enmascarar from "../assets/Enmascarar.png";
import matic from "../assets/polygon.png";
import Header from "../components/headers";
import grid from "../assets/grid.png";
import { Web3 } from "web3";
import { parseUnits } from "ethers";
import HeaderMobile from "../components/headersMobile";
import HeaderNavBar from "../components/headersNavBar";
import Select from "react-select";
import countryList from "react-select-country-list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RegisterLogin from "../components/regsiterAndLogin";
import Veriff from "../components/Veriff";
import convert from '../assets/two-arrows.png';

var Scroll = require("react-scroll");

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const tweet = () => {
  window.open("https://x.com/basemewcoin/");
};

const tg = () => {
  window.open("https://t.me/basemewcoinchat");
};

let ABIToken = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "initialSupply",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "allowance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
      }
    ],
    "name": "ERC20InsufficientAllowance",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
      }
    ],
    "name": "ERC20InsufficientBalance",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "approver",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidApprover",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidReceiver",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidSender",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidSpender",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];
let addressToken = "0xCEcd0df29D858fEf05AE131bb22EE21b3BF6bcE9"

let ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "Cancel",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "Claim",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "softCap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "hardCap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "startAt",
        "type": "uint32"
      },
      {
        "indexed": false,
        "internalType": "uint32",
        "name": "endAt",
        "type": "uint32"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "minAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "maxAmount",
        "type": "uint256"
      }
    ],
    "name": "Launch",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "caller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "PledgeCanceled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "caller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "PledgeConfirmed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "caller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "PledgeIntention",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "caller",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "Refund",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "EURtoPOL",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "POLToEUR",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "POLToGHUT",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cancel",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cancelPledge",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_wallet",
        "type": "address"
      }
    ],
    "name": "cancelPledgeTo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "canceled",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "claimed",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_wallet",
        "type": "address"
      }
    ],
    "name": "confirmPledge",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimalCorrecter",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deposit",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "distribution",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "eligibleGHUTTokens",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "endAt",
    "outputs": [
      {
        "internalType": "uint32",
        "name": "",
        "type": "uint32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "exchangeRate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getChainlinkDataFeedLatestAnswer_USD_EUR",
    "outputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getChainlinkDataFeedLatestAnswer_USD_POL",
    "outputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getHardCapInPol",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getPledgeAddressList",
    "outputs": [
      {
        "internalType": "address[]",
        "name": "",
        "type": "address[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getSoftCapInPol",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "hardCap",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "holded",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "holdedAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "holdedAmountInEuros",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_softCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_hardCap",
        "type": "uint256"
      },
      {
        "internalType": "uint32",
        "name": "_startAt",
        "type": "uint32"
      },
      {
        "internalType": "uint32",
        "name": "_endAt",
        "type": "uint32"
      },
      {
        "internalType": "uint256",
        "name": "_minAmount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_maxAmount",
        "type": "uint256"
      }
    ],
    "name": "launch",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pledge",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "pledgeList",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pledged",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "pledgedAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "refund",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "wallet",
        "type": "address"
      }
    ],
    "name": "refundTo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address payable",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "returnFunds",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_df",
        "type": "address"
      }
    ],
    "name": "setDataFeed_USD_EUR",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_df",
        "type": "address"
      }
    ],
    "name": "setDataFeed_USD_POL",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_decimalCorrecter",
        "type": "uint256"
      }
    ],
    "name": "setDecimalCorrecter",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "_endAt",
        "type": "uint32"
      }
    ],
    "name": "setEndAt",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_exchangeRate",
        "type": "uint256"
      }
    ],
    "name": "setExchangeRate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "setExchangeRateFixed",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_hardCap",
        "type": "uint256"
      }
    ],
    "name": "setHardCap",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_holded",
        "type": "uint256"
      }
    ],
    "name": "setHolded",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_maxAmount",
        "type": "uint256"
      }
    ],
    "name": "setMaxAmount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_minAmount",
        "type": "uint256"
      }
    ],
    "name": "setMinAmount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_pledged",
        "type": "uint256"
      }
    ],
    "name": "setPledged",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_softCap",
        "type": "uint256"
      }
    ],
    "name": "setSoftCap",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint32",
        "name": "_startAt",
        "type": "uint32"
      }
    ],
    "name": "setStartAt",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "softCap",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "startAt",
    "outputs": [
      {
        "internalType": "uint32",
        "name": "",
        "type": "uint32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tokenWithdrawal",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawAnyLeftbalance",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
];
let address = "0x4aA1851F516769dfDEe86cdE5D85C1e77C09dfB2"

let ABIWETH = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "guy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Withdrawal",
    type: "event",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "guy",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "deposit",
    outputs: [],
    payable: true,
    stateMutability: "payable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];
let addressWETH = "0x4200000000000000000000000000000000000006";

const HomeGhut = () => {
  const [_percentage, setPercentage] = useState("0%");
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
  const [_amount, set_amount] = useState(1);
  const httpURL = "https://go.getblock.io/139e814351f54f169e78cf8776eb0b1e";
  const [_quantity, set_quantity] = useState(0);
  const [_validate, set_validate] = useState(0);
  const [_error, set_error] = useState(0);
  const [showErrorDiv, setshowErrorDiv] = useState(false);
  const [statusError, setstatusError] = useState(false);
  const [statusLoading, setstatusLoading] = useState(false);
  const [_owner, set_owner] = useState("");
  const [_connected, setConnected] = useState(false);
  const { disconnect } = useDisconnect();
  const [_success1, setSuccessMsg1] = useState("");
  const [_loading1, setLoadingMsg1] = useState("");
  const [_ErrorMsg1, setErrorMsg1] = useState("");
  const [success, setsuccess] = useState(false);
  const [_viewUser, set_getUser] = useState("");
  const [_totalUsers, set_totalUsers] = useState("");
  const [_getAddressById, set_getAddressById] = useState("");
  const [txnHash, setTxnHash] = useState("");
  const [_totalTokensSold, setTotalTokensSold] = useState(0);
  const [_totalTokensForSale, setTotalTokensForSale] = useState(0);
  const [_targetFunds, setTargetFunds] = useState(0);
  const [_totalFundsCollected, setTotalFundsCollected] = useState(0);
  const [_rate, setRate] = useState(0);
  const [_pledged, setPledged] = useState(0);
  const [_hardCap, setHardCap] = useState(0);
  const [_EURToPOL, setEURToPOL] = useState(0);
  const [_HardCapInPOL, setHardCapInPOL] = useState(0);
  const [_softCap, setSoftCap] = useState(0);
  const [_login, set_login] = useState(0);
  const [_register, set_register] = useState(0);
  const [_softCapTargetPercentage, set_softCapTargetPercentage] =
    useState("0%");
  const [_pledgedPercentage, set_pledgedPercentage] = useState("0%");
  const options = countryList().getData();
  const [dob, setDob] = useState(null);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [_BirthDate, setBirthDate] = useState(0);
  const [_Country, setCountry] = useState("");
  const [_Password, setPassword] = useState("");
  const [_Password2, setPassword2] = useState("");
  const [_Email, setEmail] = useState("");
  const [_FirstName, setFirstName] = useState("");
  const [_LastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [_ghutAmount, setGhutAmount] = useState(0);
  const [_GhutToPol, setGhutToPol] = useState(0);
  const [_EndAt, setEndAt] = useState(0);
  const [_StartAt, setStartAt] = useState(0);
  const [_MaxAmount, setMaxAmount] = useState(0);
  const [_MinAmount, setMinAmount] = useState(0);
  const [_pol1000ToGhut, setPol1000ToGhut] = useState(0);
  const [_userToken, setUserToken] = useState(0);
  const [_ShowMsgDiv, setShowMsgDiv] = useState(0);
  const [_investment, setInvestment] = useState(0);
  const [isLoginSuccess, setIsLoginSuccess] = useState(null);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [isInvestmentSuccess, setIsInvestmentSuccess] = useState(null);
  const [responseMessageInvestment, setResponseMessageInvestment] =
    useState("");
  const [_Investment2, set_Investment2] = useState(0);
  const [polygonBalance, setPolygonBalance] = useState(0);
  const [openVeriff, setOpenVeriff] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [_UserVerifiedLocal, setUserVerifiedLocal] = useState(false);
  const [_ValidIdentity, setValidIdentity] = useState(false);


  //   const [firstName, setFirstName] = useState("");
  //   const [lastName, setLastName] = useState("");

  const { address: walletAddress } = useAccount({
    async onConnect() {
      handleConnect();
    },
  });

  const transport = http(httpURL);

  const publicClient = createPublicClient({
    chain: sepolia,
    transport,
  });

  var contract = {
    address: address,
    abi: ABI,
  };

  var contract2 = {
    address: addressToken,
    abi: ABIToken,
  };

  var contract3 = {
    address: addressWETH,
    abi: ABIWETH,
  };

  // Helper function to generate options
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const days = generateOptions(1, 31);
  const months = generateOptions(1, 12);
  const years = generateOptions(1960, new Date().getFullYear());

  // Combine day, month, and year into a single date variable
  const getDateValue = () => {
    if (day && month && year) {
      const date = new Date(year, month - 1, day); // Months are zero-based in JavaScript
      return date.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
    }
    return "";
  };

  const changeHandler = (country) => {
    setCountry(country);
  };

  const handleDateChange = (date) => {
    setDob(date);
  };

  async function handleConnect() {
    if (chain.id !== 11155111) {
      switchNetwork(11155111);
    }

    //await fetchNFTs();
    setConnected(true);
  }

  function formatNumber(number) {
    // Convert number to string and split into integer and decimal parts
    let [integerPart, decimalPart] = number.toString().split(".");

    // Add commas every three digits from the right for the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the integer part with the decimal part if it exists
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }

  useEffect(() => {
    let hardcap;
    let softCap;
    let pledged;

    var _userToken2 = sessionStorage.getItem("userToken");
    setUserToken(_userToken2);
    console.log("_userToken2 : " + _userToken2);
    fetchData2();

    async function pol1000ToGhut() {
      var pol1000ToGhut = (_rate * 1000) / 10 ** 18;
      setPol1000ToGhut(pol1000ToGhut);
      console.log("_pol1000ToGhut : " + pol1000ToGhut);
    }

    async function fetchData2() {
      var data = await getRate();
      let rate = Number(data.data);
      setRate(Number(data.data));
      console.log(
        "GHUT AMOUNT : " + (Number(_ghutAmount) * Number(data.data)) / 10 ** 18
      );
      //set_amount((Number(_ghutAmount) * Number(data.data)) / 10 ** 18);

      set_amount(((Number(_ghutAmount) * 10 ** 18) / Number(data.data)));

      console.log("rate : " + rate);
    }

    async function fetchData5() {
      var data = await getSoftCap();
      softCap = Number(data.data);
      setSoftCap(softCap);
      console.log("softCap : " + softCap);

      var data = await getHardCap();
      hardcap = Number(data.data);
      setHardCap(hardcap);
      console.log("hardcap : " + hardcap);

      var data = await getPledged();
      pledged = Number(data.data);
      setPledged(pledged);
      console.log("pledged : " + pledged);

      await caculatePledgedPercentage();
      await calculateSoftCapPercentage();
    }

    async function fetchHardCapInPOL() {
      try {
        var data = await getHardCapInPol();
        let hardCapPOL = Number(data.data);
        console.log("Hard Cap in POL: " + hardCapPOL);

        // Set the hard cap in POL (if you have a state or variable to store it)
        setHardCapInPOL(hardCapPOL);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    // Call the function to fetch and calculate the hard cap in POL
    fetchHardCapInPOL();

    async function calculateSoftCapPercentage() {
      var softCapTargetPercentage = (softCap * 100) / hardcap;
      set_softCapTargetPercentage(softCapTargetPercentage.toFixed(2));
      console.log("softCapTargetPercentage: " + softCapTargetPercentage);
    }

    async function caculatePledgedPercentage() {
      console.log("pledged_percentage : " + pledged);
      console.log("hardcap_percentage : " + _HardCapInPOL);

      var pledgedPercentage = Number((pledged * 100) / _HardCapInPOL);
      //set_pledgedPercentage(Number(pledgedPercentage));
      set_pledgedPercentage(pledgedPercentage.toFixed(2) + "%");
      console.log("pledgedPercentage: " + pledgedPercentage);
    }

    async function tokenAmount() {
      var data = await getTokenAmount();
      let tokenAmount = Number(data.data);
      setRate(tokenAmount);
      console.log("tokenAmount : " + tokenAmount);
    }

    async function fetchMinAmount() {
      var data = await getMinAmount();
      let minAmount = Number(data.data);
      setMinAmount(minAmount);
      console.log("minAmount : " + minAmount);
    }

    async function fetchBalance() {
      var data = await getBalanceFunction();
      let getBalance = Number(data.data);
      setPolygonBalance(getBalance);
      console.log("minAmount : " + getBalance);
    }

    async function fetchMaxAmount() {
      var data = await getMaxAmount();
      let maxAmount = Number(data.data);
      setMaxAmount(maxAmount);
      console.log("maxAmount : " + maxAmount);
    }

    async function fetchStartAt() {
      var data = await getTokenAmount();
      let startAt = Number(data.data);
      setStartAt(startAt);
      console.log("startAt : " + startAt);
    }

    async function fetchEndAt() {
      var data = await getTokenAmount();
      let endAt = Number(data.data);
      setEndAt(endAt);
      console.log("endAt : " + endAt);
    }

    console.log("inner");
    if (userVerified) {
      console.log("User verified successfully!");
      // Will be removed once DB has fixed
      investmentDiv();

    }

    console.log("isValidIdentity_home : " + sessionStorage.getItem('isValidIdentity'));
    setValidIdentity(sessionStorage.getItem('isValidIdentity'));

    if (sessionStorage.getItem('isValidIdentity') === 'false') {
      console.log("isValidIdentity__1 :" + sessionStorage.getItem('isValidIdentity'));
    } else {
      console.log("isValidIdentity__2");
    }

    ghutToPol();
    fetchData5();
    calculateSoftCapPercentage();
    caculatePledgedPercentage();
    fetchHardCapInPOL();

    fetchMinAmount();
    fetchMaxAmount();
    fetchStartAt();
    fetchEndAt();
    pol1000ToGhut();
    fetchBalance();

    if (_connected) {
      tokenAmount();
    }

    console.log("_connected : " + _connected);
    console.log("_login : " + _login);
  }, [
    _connected,
    walletAddress,
    _ghutAmount,
    _amount,
    _GhutToPol,
    _pol1000ToGhut,
    userVerified
  ]);

  async function ghutToPol() {
    var ghutToPol = Number(1000 * (10 ** 18 / Number(_rate)));
    setGhutToPol(ghutToPol);
    console.log("ghutToPol : " + ghutToPol);
  }

  function shortenAddress(walletAddress) {
    try {
      return _connected
        ? walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4)
        : "Connect";
    } catch (error) {
      console.log(error);
    }
  }

  async function disconnectWallet() {
    setConnected(false);
    disconnect();
    window.location.reload(true);
  }

  const { writeAsync } = useContractWrite({
    onError(error) {
      if (error.message.includes("balance")) {
        setstatusError(true);
        setstatusLoading(false);
      }
    },
  });

  async function pledge() {
    try {
      // Convert _amount to a string if it's not already
      const amountStr = String(_amount);

      if (!amountStr || parseFloat(amountStr) <= 0) {
        setErrorMsg1("Invalid amount");
        return;
      }

      setstatusLoading(true);
      setInvestment(0);

      // Convert amountStr to Wei
      const amountInWei = parseUnits(amountStr, "ether");
      console.log("IM IN THE PLEDGE FUNCTION");
      console.log("amountInWei : " + amountInWei);

      const res = await writeAsync({
        ...contract,
        functionName: "pledge",
        value: amountInWei.toString(),
      });

      const txnHash = res.hash;
      setTxnHash(txnHash);
      console.log("Transaction hash:", txnHash);

      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Wait for transaction receipt
      const result = await publicClient.waitForTransactionReceipt(res);

      console.log("Transaction result:", result);

      if (result.status === "success") {
        setstatusError(false);
        setshowErrorDiv(false); // Hide the error div on success
        setsuccess(true);
        setstatusLoading(false);
        setSuccessMsg1("Transaction Successful!");
        setLoadingMsg1("");

        investment();
      } else if (result.status) {
        setshowErrorDiv(false); // Hide the error div on failure
        setsuccess(false);
        setstatusError(true);
        setstatusLoading(false);
      }
    } catch (e) {
      console.error("Transaction failed:", e);
      if (e.message.includes("Transaction with hash")) {
        investment();
        console.log("adkiuyhsalofdjalkhfjdalskhf");
      }
      if (e.message.includes("err: insufficient funds for gas")) {
        setErrorMsg1("Insufficient funds");
        setshowErrorDiv(true); // Show the error div for insufficient funds
        set_validate(0);
      } else if (e.message.includes("User rejected the request")) {
        setErrorMsg1("User Rejected");
        setshowErrorDiv(false);
      } else {
        setErrorMsg1("Sorry, something went wrong");
        setshowErrorDiv(false);
      }

      setstatusError(true);
      setstatusLoading(false);
    }
  }


  const { refetch: getRate } = useContractRead({
    ...contract,
    functionName: "POLToGHUT",
    args: [],
  });

  const { refetch: getHardCap } = useContractRead({
    ...contract,
    functionName: "hardCap",
    args: [],
  });

  const { refetch: getMinAmount } = useContractRead({
    ...contract,
    functionName: "minAmount",
    args: [],
  });

  const { refetch: getMaxAmount } = useContractRead({
    ...contract,
    functionName: "maxAmount",
    args: [],
  });

  const { refetch: getBalanceFunction } = useContractRead({
    ...contract,
    functionName: "getBalance",
    args: [],
  });

  const { refetch: getStartAt } = useContractRead({
    ...contract,
    functionName: "startAt",
    args: [],
  });

  const { refetch: getEndAt } = useContractRead({
    ...contract,
    functionName: "endAt",
    args: [],
  });

  const { refetch: getHardCapInPol } = useContractRead({
    ...contract,
    functionName: "getHardCapInPol",
    args: [],
  });

  const { refetch: getSoftCap } = useContractRead({
    ...contract,
    functionName: "softCap",
    args: [],
  });

  const { refetch: getPledged } = useContractRead({
    ...contract,
    functionName: "pledged",
    args: [],
  });

  const { refetch: getTokenAmount } = useContractRead({
    ...contract2,
    functionName: "balanceOf",
    args: [walletAddress],
  });

  async function validateWindow() {
    set_validate(1);
  }

  async function closeWindow() {
    set_validate(0);
    setshowErrorDiv(false);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "5px",
      borderColor: "#ccc",
      padding: "5px",
      margin: "10px 0",
      textAlign: "left",
      boxShadow: state.isFocused ? `0 0 0 2px #8c40dd` : "none", // Outline color on focus
      border: state.isFocused ? "1px solid #8c40dd" : "1px solid #ccc", // Border color change on focus
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  // Close the popup div
  const closediv = () => {
    setIsLoginSuccess(null); // Reset the state to hide the divs
    setIsInvestmentSuccess(null);
    setIsRegisterSuccess(null);
  };

  const investmentDiv = async () => {
    set_validate(0);

    try {
      //only execute below if user is verified by veriff.

      const payload = {
        isValidIdentity: true,
        verifiedSession: "",
        verifiedResult: "",
      };

      const response = await axios.put(
        "http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Investor/verifyUser",
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
          },
        }
      );
      console.log("Verified successfully!");
      console.log(response.data);

      if (response.data.isSuccess) {
        setInvestment(1);
        sessionStorage.setItem('isValidIdentity', response.data.data.isValidIdentity);
        console.log("isValidIdentity_Check1 : " + response.data.data.isValidIdentity);
        console.log("isValidIdentity_Check2 : " + sessionStorage.getItem('isValidIdentity'));
      } else {
      }
    } catch (error) {
      console.log("Error during Transaction.", error);
      setIsLoading(false); // Set loading to false
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  async function imageGet() {
    // Get userId from sessionStorage
    const userId = sessionStorage.getItem("userId");
    if (!userId) {
      console.error("User ID not found in sessionStorage.");
      return;
    }

    try {
      const response = await axios.get(
        `http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/User/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
          },
        }
      );
      console.log("User image data retrieved successfully:", response.data);

      // You can now use response.data to update the user image section
    } catch (error) {
      console.error(
        "Error fetching user image:",
        error.response?.data || error.message
      );
    }
  }

  const investment = async () => {
    setResponseMessageInvestment(""); // Clear any previous response message
    setIsLoading(true); // Set loading to true

    try {
      const payload = {
        ethContract: "0xCB1003a3D297fbE6D9B91F87Bb876728E06C1Bbd",
        ethToken: addressToken,
        ethAmount: Number(_amount),
        ethApprovalTx: "",
        ethPledgeTx: txnHash,
        saftPdf: "",
        ethTokenDecimals: 6,
        ghutAmount: Number(_ghutAmount),
        ghutTotal: 0,
        saftMail: true,
      };

      if (_connected) {
        payload.ethAddress = walletAddress;
      }

      console.log("Transaction USER");

      const response = await axios.post(
        "http://api-dev.eba-padses2n.eu-west-3.elasticbeanstalk.com/api/Investment",
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
          },
        }
      );
      console.log("Transaction completed successfully!");
      console.log(response.data);

      console.log("_InvestorisSuccess : " + response.data.isSuccess);
      console.log("_Investormessage : " + response.data.message);

      if (response.data.isSuccess) {
        const token = response.data.data.token; // Extract the token from the response
        setUserToken(token);
        setIsInvestmentSuccess(true); // Set success
        setResponseMessageInvestment("Login successful!");
        set_Investment2(0);
        setTimeout(() => {
          window.location.reload(true);
        }, 6000);
      } else {
      }
    } catch (error) {
      console.log("Error during Transaction.", error);
      setIsLoading(false); // Set loading to false
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  async function pledgeButtonClick() {
    if (sessionStorage.getItem('isValidIdentity') === 'true') {
      pledge()
    } else {
      validateWindow()
    }

  }

  const handleVerify = async () => {
    const url = "https://stationapi.veriff.com/v1/sessions";
    const headers = {
      "Content-Type": "application/json",
      "X-AUTH-CLIENT": "36fcaf51-6d70-4292-adf1-6d55a0eea4f3",
    };
    const body = {
      verification: {
        person: {
          firstName: _FirstName,
          lastName: _LastName,
        },
        vendorData: Cookie.get("id"),
      },
    };
    try {
      const response = await axios.post(url, body, { headers });
      console.log("Veriff response:", response.data);
      if (response.data.verification.url) {
        window.open(response.data.verification.url, "_blank");
      }
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };

  async function checkAndAddUser(username) {
    // Retrieve the stored array of usernames, or start with an empty array if not found
    let storedUsernames = localStorage.getItem('usernames');
    let usernamesArray = storedUsernames ? JSON.parse(storedUsernames) : [];

    // Check if the username already exists in the array
    if (usernamesArray.includes(username)) {
      console.log(`User ${username} has already visited.`);
      return true;  // Username already exists
    } else {
      // Add the new username to the array

      return false;  // New username added
    }
  }

  async function checkAndAddUser2(username) {
    // Retrieve the stored array of usernames, or start with an empty array if not found
    let storedUsernames = localStorage.getItem('usernames');
    let usernamesArray = storedUsernames ? JSON.parse(storedUsernames) : [];

    // Check if the username already exists in the array
    if (usernamesArray.includes(username)) {
      console.log(`User ${username} has already visited.`);
    } else {
      // Add the new username to the array
      usernamesArray.push(username);
      localStorage.setItem('usernames', JSON.stringify(usernamesArray));
      console.log(`User ${username} is added for the first time.`);
    }
  }

  return (
    <div className="allWrapMain">
      {openVeriff && (
        <div className="popup-containerMain3">
          <div className="popup-container3">
            <div className="popup success">
              <div
                className="close"
                onClick={() => {
                  setOpenVeriff(false);
                }}
              >
                ✖
              </div>
              <p className="responseSuccess">
                <Veriff
                  firstName={_FirstName}
                  lastName={_LastName}
                  id={sessionStorage.getItem("userId")}
                  setVerified={setUserVerified}
                />
              </p>
            </div>
          </div>
        </div>
      )}
      {/* <Veriff /> */}

      <div className="allWrap">
        <div class="light">
          {_connected ? (
            <div className="connectT" onClick={() => disconnectWallet()}>
              {walletAddress === ""
                ? "Conectar Billetera Web3"
                : shortenAddress(walletAddress)}
            </div>
          ) : (
            <div
              className="connectT"
              onClick={() => {
                open();
              }}
            >
              Conectar Billetera Web3
            </div>
          )}

          <div className="onlyMob">
            <HeaderMobile />
          </div>

          <div className="page1Main">
            <Header />

            <div className="rightSection">
              <div className="mainT">HOME</div>
              <div className="rightSection--2">
                <div className="rightSection-1">
                  <div className="title1Rsection" id="txtCenter">
                    DATA CONSENTSYSTEM AND MARKETPLACE
                  </div>
                  <div className="title2Rsection">
                    INVIRTE EN NUESTRO PROYECTO!
                  </div>

                  <div className="details">
                    {/*<div id="de1">PRE-LANZAMIENTO 1RA RONDA: 1000 POL = {_pol1000ToGhut.toFixed(2)} GHUT</div>
										<div id="de2">VENTA PÚBLICA EST: 1000 GHUT = 253 POL</div>*/}
                    <div id="de3">
                      MÍNIMO DE INVERSIÓN: 100 EUROS EQUIVALENTES EN POL /
                      MÁXIMO DE INVERSIÓN: 50,000 EUROS EQUIVALENTES EN POL
                    </div>
                  </div>

                  <div className="note">
                    Si la inversión a realizar es mayor al saldo de token
                    disponibles, se indican los token disponibles para poder
                    realizar la inversión.
                  </div>

                  <div className="flexBox">
                    <div className="purpleBox">
                      <div className="boxT1">LLEVAMOS RECAUDADO </div>
                      <div className="boxT2">
                        POL {(polygonBalance / 10 ** 18).toFixed(2)}
                      </div>
                      <div className="boxT3">
                        Nuestro objetivo es POL{" "}
                        {Number(_HardCapInPOL / 10 ** 18).toFixed(2)}
                      </div>

                      <div class="progress-container">
                        <div
                          class="progress-bar"
                          style={{ width: "50.23255813953488%" }}
                        ></div>
                      </div>

                      <div
                        className="_softCapTargetPercentage"
                        style={{ width: _pledgedPercentage }}
                      >
                        {_pledgedPercentage}
                      </div>

                      <div className="giftSection">
                        <img src={gift} />
                        <div>
                          <span className="giftSectionP1">
                            Con tu inversión recibes de regalo el Token GHGO y
                            un NFT coleccionable.
                          </span>
                          Con el Token GHGO formas parte de la lista de
                          inversores tempranos.
                          <br />
                          <span className="underline">
                            Revisa los detalles en la carta blanca.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="blackBox">
                      <div className="imgs2BB">
                        <img className="imgs2_1" src={audit} />
                        <img className="imgs2_2" src={Enmascarar} />
                      </div>
                      <div className="line"></div>
                      <div className="box2Main">
                        <div className="bbTitle">
                          <span id="bbTitle2">GARANTÍA DE</span> DEVOLUCIÓN DEL
                          100% DE TU INVERSIÓN
                        </div>
                        <div className="bbTitle3">
                          Nos comprometemos a reembolsar el 100% de las
                          inversiones en caso de no alcanzar el capital
                          requerido para el proyecto (los gastos de las
                          transacciones son a cargo del inversor)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rightSection-2">
                  <div className="flexBox2">
                    <div className="flexBox2T">INVIERTE AHORA</div>
                    <div className="amountBox">

                      <div className="amountBoxMain">
                        <div className="amountBox1">
                          <div className="sendCoin">Recibes GHUT</div>
                          <div className="amount">
                            <input
                                 id="inputIvest"
                                 type="number"
                                 step="0.001"
                                 required
                                 name="amounts"
                                 value={_ghutAmount}
                                 onChange={(event) => {
                                   const value = event.target.value;
   
                                   // Allow empty string, or any non-negative number (including decimals)
                                   if (value === "" || !isNaN(value) && Number(value) >= 0) {
                                     setGhutAmount(value);
                                   }
                                 }}
                                 onBlur={() => {
                                   // Set default value to "1" if the field is empty when user leaves the input
                                   if (_ghutAmount === "" || _ghutAmount === undefined) {
                                    setGhutAmount("1");
                                   }
                                 }}
                            />
                          </div>
                        </div>
                        <div className="amountBox2">
                          <img className="ghutAmount" src={Enmascarar} />
                        </div>
                      </div>

                      <img className="convert" src={convert} onClick={() => window.location.href = '/'} />

                      <div className="amountBoxMain">
                        <div className="amountBox1">
                          <div className="sendCoin">Envías POL</div>
                          <div className="amount">
                            <input
                            readOnly
                              type="number"
                              placeholder={_amount.toFixed(4)}
                            />

                          </div>
                        </div>
                        <div className="amountBox2">
                          <img className="usdtAmount" src={matic} />
                        </div>
                      </div>

                      {!_connected ? (
                        <button className="invest" disabled>
                          INVERTIR
                        </button>
                      ) : (

                        <button onClick={pledgeButtonClick}>INVERTIR</button>

                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {_validate > 0 ? (
            <div class="popup-containerMain">
              <div class="popup-container">
                <div className="close" onClick={closeWindow}>
                  ✖
                </div>
                <div className="validateT">
                  NECESITAMOS VALIDAR TU IDENTIDAD PARA CONFIRMAR LA INVERSIÓN.
                </div>
                <div className="validateInputDiv">
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    placeholder="Nombre"
                    value={_FirstName}
                  ></input>
                  <input
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    placeholder="Apellido"
                    value={_LastName}
                  ></input>
                </div>

                <button
                  className="validate"
                  onClick={async () => {
                    // closeWindow();
                    setOpenVeriff(true);
                    // investmentDiv();
                    // await handleVerify();
                    // setFirstName("");
                    // setLastName("");
                    // closeWindow();
                  }}
                >
                  Validar
                </button>
              </div>
            </div>
          ) : null}


          {showErrorDiv ? (
            <div className="popup-containerMain">
              <div className="popup-container2">
                <div className="close" onClick={closeWindow}>
                  ✖
                </div>
                <div className="errorT">¡NO TIENES SALDO!</div>
                <div className="errorP">
                  Actualiza o carga tu billetera virtual para que puedas
                  continuar con tu inversión
                </div>
                <div className="errorFlexBtns">
                  <button
                    className="validate"
                    onClick={() => {
                      open();
                    }}
                  >
                    METAMASK
                  </button>
                  <button className="validate">MOONPAY</button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <img className="grid" src={grid} />
      </div>

      <div className="onlyMob">
        <HeaderNavBar />
      </div>

      <div>
        {isInvestmentSuccess === true && (
          <div className="popup-containerMain3">
            <div className="popup-container3">
              <div className="popup success">
                <div className="close" onClick={closediv}>
                  ✖
                </div>
                <p className="responseSuccess">Congratulations!</p>
                <div className="review">
                  Your transaction has been completed successfully.
                </div>
              </div>
            </div>
          </div>
        )}

        {isInvestmentSuccess === false && (
          <div className="popup-containerMain3">
            <div className="popup-container3">
              <div className="popup error">
                <div className="close" onClick={closediv}>
                  ✖
                </div>
                <p className="responseSuccess">
                  An error occurred while processing your request.
                </p>
                <div className="review2">Please try again later.</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {_investment > 0 ? (
        <div className="popup-containerMain3">
          <div className="popup-container3">
            <div className="popup success">
              <p className="responseSuccess">Verification Successful!</p>
              <button className="proceed" onClick={pledge}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <RegisterLogin />
    </div>
  );
};
export default HomeGhut;

import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import Invest from './Pages/invest';
import InvestGHUT from './Pages/investGhut';
import MyInvestments from './Pages/myInvestments';
import Notifications from './Pages/notifications';
import MyAccount from './Pages/myaccount';
import Admin from './Pages/admin';
import HomeGhut from './Pages/Homeghut';
 
function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='invest' element={<Invest />} />
					<Route path='myInvestments' element={<MyInvestments />} />
					<Route path='notifications' element={<Notifications />} />
					<Route path='myAccount' element={<MyAccount />} />
					<Route path='admin' element={<Admin />} />
					<Route path='ghut' element={<HomeGhut />} />
					<Route path='InvestGHUT' element={<InvestGHUT />} />
  
				</Routes>
			</BrowserRouter>
			<div>
		

			</div>
		</div>




	)
}


export default App;
